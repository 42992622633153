import { result } from 'lodash';

export const checkResponseStatus = store => next => action => {
  const responseError = result(action, 'error.response.status');
  if (responseError === 401) {
    //TODO: store.dispatch(logout());
  }

  return next(action);
};
