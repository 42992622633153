import { useEffect } from 'react';

import {
  formatQueryString,
  MERCURY_HASH,
  MERCURY_HOSTNAME,
  MERCURY_TIMING,
  trackPage,
  WEB_PAGEVIEW,
} from 'shared/util/tracking/mercury';

import { mixpanelTrack } from '../vendor/mixpanel';

import { useLocation } from 'react-router-dom';

let referrer: string | null = null;

export default function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {

    const fields = {
      annotations: null,
      hash: MERCURY_HASH,
      hostName: MERCURY_HOSTNAME,
      path: location.pathname,
      queryParams: formatQueryString(window.location.search),
      referrer: referrer !== null ? referrer : null,
      sessionFlowId: null,
      timing: MERCURY_TIMING,
    };

    mixpanelTrack('Page view');
    trackPage(WEB_PAGEVIEW, fields);
    referrer = location.pathname;
  }, [location]);
  return null;
}
