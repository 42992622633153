import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import FontIcon from 'wiw/ui/FontIcon';

import classnames from 'classnames';
import { Transition } from 'react-transition-group';

const animationTime = 500;

class Notice extends Component {

  noticeRef = createRef();
  dismissTimeout = null;
  startTime = null;
  remainingTime = 0;

  state = {
    maxHeight: 'none',
  };

  static propTypes = {
    in: PropTypes.bool,
    status: PropTypes.string,
    showDismiss: PropTypes.bool,
    duration: PropTypes.number,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
    animation: PropTypes.bool,
    onDismiss: PropTypes.func.isRequired,
  };

  static defaultProps = {
    duration: 3,
    status: null,
    showDismiss: true,
    className: '',
    onDismiss: () => null,
  };

  componentWillUnmount() {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
      this.dismissTimeout = null;
    }
  }

  componentDidMount() {
    this.onEnter();
    if (this.props.duration > 0) {
      this.remainingTime = (this.props.duration * 1000);
      if (this.props.animation) {
        this.remainingTime += animationTime * 2;
      }
      this.dismissTimeout = setTimeout(this.props.onDismiss, this.remainingTime);
      this.startTime = new Date();
    }
  }

  onMouseEnter = e => {
    e.stopPropagation();
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
      this.dismissTimeout = null;
      this.remainingTime -= new Date() - this.startTime;
    }
  };

  onMouseLeave = e => {
    e.stopPropagation();
    if (this.props.duration) {
      this.dismissTimeout = setTimeout(this.props.onDismiss, this.remainingTime);
      this.startTime = new Date();
    }
  };

  onEnter = () => {
    const node = this.noticeRef.current;
    let maxHeight = node.offsetHeight;
    const style = getComputedStyle(node);
    maxHeight += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    this.setState({ maxHeight });
  };

  getClassFromStatus(status) {
    return {
      'success': 'alert-success',
      'error': 'alert-danger',
      'info': 'alert-info',
      'warning': 'alert-warning',
    }[status] || '';
  }

  getStyles(state) {
    if (state === 'entering' || state === 'entered') {
      return { maxHeight: this.state.maxHeight };
    }
    return { maxHeight: 0 };
  }

  render() {
    const {
      in: inProp,
      className,
      status,
      showDismiss,
      onDismiss,
      content,
      animation,
    } = this.props;

    const classNames = classnames(
      'notice',
      className,
      this.getClassFromStatus(status),
    );

    const notice = (
      <div
        ref={ this.noticeRef }
        className={ classNames }
        onMouseEnter={ this.onMouseEnter }
        onMouseLeave={ this.onMouseLeave }
      >
        <div className="notice-content">{ content }</div>

        {showDismiss && (
          <button
            type="button"
            className="close-notice"
            onClick={ onDismiss }
            aria-label="Dismiss"
          >
            <FontIcon icon="close" className="close-notice lg" />
          </button>
        )}
      </div>
    );

    if (animation) {
      return (
        <Transition in={ inProp }
          timeout={ animationTime }
          unmountOnExit={ true }
          appear={ true }
        >
          {state => (
            <div
              className={ `notice-wrapper notice-is-${state}` }
              style={ this.getStyles(state) }
            >
              { notice }
            </div>
          )}
        </Transition>
      );
    }

    return notice;
  }
}

export default Notice;
