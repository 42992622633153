import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getAuthAccount, getAuthPerson, getAuthUser } from 'shared/auth/selectors';
import AppBranches from 'shared/dev/components/AppBranches';
import LoginBranches from 'shared/dev/components/LoginBranches';
import ReactBranches from 'shared/dev/components/ReactBranches';
import Environment from 'shared/util/environment';
import FontIcon from 'wiw/ui/FontIcon';

import Cookie from 'js-cookie';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import 'shared/dev/styles/devPanel.scss';

export class DevPanel extends Component {

  static propTypes = {
    loginId: PropTypes.string,
    currentUser: PropTypes.object,
    currentAccount: PropTypes.object,
  };

  state = {
    isDevPanelOpen: false,
  };

  toggleDevPanel = () => {
    this.setState({ isDevPanelOpen: !this.state.isDevPanelOpen });
  };

  devPanelShouldShow = () => {
    return !Environment.isProduction() || Cookie.get('X-App-Debug');
  };

  renderPanel() {
    const { loginId, currentUser, currentAccount } = this.props;

    return (
      <Fragment>
        <div className="dialogs--holder dialog-kit-scroll dimmed">
          <Modal
            style={ { width: '90%', maxWidth: 750 } }
            className="modal-dev-panel"
            toggle={ this.toggleDevPanel }
            isOpen={ this.state.isDevPanelOpen }>
            <ModalHeader toggle={ this.toggleDevPanel }>
              Dev Panel
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md branchPickers mb-3">
                  <div className="row">
                    { !Environment.isProduction() && <ReactBranches label="WIWJS Branch" /> }
                    { !Environment.isProduction() && <LoginBranches label="Login-JS Branch" /> }
                    { !Environment.isProduction() && <AppBranches label="App Branch" /> }
                  </div>
                </div>
              </div>
              <ul className="list-group">
                <li className="list-group-item justify-content-between">
                  Current Login ID:
                  <span className="badge badge-default">{ loginId }</span>
                </li>
                <li className="list-group-item justify-content-between">
                  Current User ID:
                  <span className="badge badge-default">{ currentUser?.id }</span>
                </li>
                <li className="list-group-item justify-content-between">
                  Current User Role:
                  <span className="badge badge-default">{ currentUser?.roleName() }</span>
                </li>
                <li className="list-group-item justify-content-between">
                  Current Account ID:
                  <span className="badge badge-default">{ currentAccount?.id }</span>
                </li>
                <li className="list-group-item justify-content-between">
                  Current Account Plan:
                  <span className="badge badge-default">{ currentAccount?.planId }</span>
                </li>
              </ul>
            </ModalBody>
          </Modal>
        </div>
        <div className="modal-backdrop show" onClick={ this.toggleDevPanel } />
      </Fragment>
    );
  }

  renderButton() {
    return (
      <div className="dev-panel-link" onClick={ this.toggleDevPanel } style={ {
        position: 'absolute',
        top: 10,
        right: 56,
        padding: 10,
        zIndex: 9999,
      } }>
        <button type="button"><FontIcon icon="general-settings" className="d-print-none" /></button>
      </div>
    );
  }

  render() {
    return (
      <div className="container dev-panel">
        { this.devPanelShouldShow() && this.state.isDevPanelOpen && this.renderPanel() }
        { this.devPanelShouldShow() && this.renderButton() }
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      loginId: getAuthPerson(state)?.id,
      currentUser: getAuthUser(state),
      currentAccount: getAuthAccount(state),
    };
  },
  dispatch => bindActionCreators({}, dispatch),
)(DevPanel);
