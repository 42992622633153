import { getAnonymousUserId, getToken, getUserId } from 'shared/auth/identifiers';

function buildConfig() {
  const headers = {
    'Content-Type': 'application/json',
    'W-Date-Format': 'iso',
  };

  if (getToken()) {
    headers['W-Token'] = getToken();
    headers['Authorization'] = getToken();
  }

  if (getUserId()) {
    headers['W-UserId'] = getUserId();
  }

  return {
    headers,
  };
}

export default buildConfig;
