import { Component } from 'react';
import PropTypes from 'prop-types';

import { DialogCloseButton } from 'wiw/dialogs';

import classnames from 'classnames';

export class DialogHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func,
    style: PropTypes.object,
  };

  render() {
    const { children, className, onClose, style } = this.props;
    return (
      <div className={ classnames('dialog-header', className) } style={ style }
      >
        { onClose && <DialogCloseButton onClose={ onClose } /> }
        <h1 className='title'>{ children }</h1>
      </div>
    );
  }
}
