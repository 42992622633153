/* eslint react/no-multi-comp: off */
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Select from 'shared/components/form/inputs/Select';
import { components } from 'react-select';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { withFormsy, propTypes } from 'formsy-react';
import { InputWrapper } from 'shared/components/form/inputs';
import { countries } from 'shared/components/form/data/countryData';
import isEqual from 'lodash/isEqual';

import 'flag-icon-css/css/flag-icon.min.css';
import 'shared/components/form/styles/PhoneInput.scss';

const phoneUtil = PhoneNumberUtil.getInstance();

// Can someone please explain to me why this thing
// throws an exception if the parse fails
//
// honestly
function parseSafe(num, defaultRegion) {
  try {
    return phoneUtil.parse(num, defaultRegion);
  } catch (e) {
    return null;
  }
}

let regionOptions = null;

const Flag = props => {
  return <span className={ `flag-icon flag-icon-${props.data.value.toLowerCase()}` } />;
};
Flag.propTypes = {
  data: PropTypes.object.isRequired,
};

class CustomOption extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.data, nextProps.data);
  }

  render() {
    if (this.props.data.value) {
      return (
        <components.Option { ...this.props }>
          <Flag { ...this.props } />
          <span className="option-label">{this.props.data.label}</span>
          <span className="option-code">+{this.props.data.code}</span>
        </components.Option>
      );
    } else {
      return (
        <hr />
      );
    }
  }
}
CustomOption.propTypes = {
  data: PropTypes.object.isRequired,
};

const CustomSingleValue = props => {
  return (
    <components.SingleValue { ...props }>
      <Flag { ...props } />
    </components.SingleValue>
  );
};

const CustomMenu = props => {
  return (
    <Fragment>
      <components.Menu { ...props }>
        { props.children }
      </components.Menu>
    </Fragment>
  );
};
CustomMenu.propTypes = {
  children: PropTypes.node,
};

// The PhoneInput component can handle and validate phone numbers for all regions.
// If the provided number is valid, the Formsy value will always be in E.164 format.
export class PhoneInput extends Component {

  static propTypes = {
    ...propTypes,

    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    validations: PropTypes.string,

    setValue: PropTypes.func.isRequired,
  };

  state = {
    rawInput: '',
    selectedRegion: null,
    canShowError: false,

    // derived
    formsyValue: null,
  };

  constructor(props) {
    super(props);

    if (props.value) {
      this.state.rawInput = props.value;
      this.props.setValue(props.value);
    }

    if (!regionOptions) {
      regionOptions = [
        {
          label: 'Australia',
          value: 'AU',
          code: '61',
        },
        {
          label: 'Canada',
          value: 'CA',
          code: '1',
        },
        {
          label: 'United Kingdom',
          value: 'GB',
          code: '44',
        },
        {
          label: 'United States',
          value: 'US',
          code: '1',
        },
        {
          value: null,
        },
        ...countries.map(country => ({
          label: country[0],
          value: country[1].toUpperCase(),
          code: country[2],
        })),
      ];
    }

    this.state.selectedRegion = regionOptions[3].value;
  }

  static getDerivedStateFromProps(props, state) {
    return {
      formsyValue: props.value,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.formsyValue !== this.state.formsyValue) {
      this.updateWithInput(this.state.formsyValue, true);
    }
  }

  // Gets the phone code of the currently selected region.
  getCurrentCode = () => {
    const currentRegionInfo = regionOptions.find(opt => opt.value === this.state.selectedRegion);
    return currentRegionInfo.code;
  }

  // Checks whether the number is valid. It will attempt to guess the region based
  // on properties of the number itself, but if that fails, it will fall back to
  // the currently selected region.
  isValid = num => {
    // First check if the number has enough region info baked in to let us
    // check directly against that.
    const inherentRegion = phoneUtil.getRegionCodeForNumber(num);
    if (inherentRegion && phoneUtil.isValidNumberForRegion(num, inherentRegion)) {
      return inherentRegion;
    }

    // Otherwise, check against the selected region.
    if (phoneUtil.isValidNumberForRegion(num, this.state.selectedRegion)) {
      return this.state.selectedRegion;
    }

    return false;
  }

  handleRegionChange = (name, value) => {
    this.setState({ selectedRegion: value }, () => {
      this.updateWithInput(this.state.rawInput, true);
    });
  }

  handleChange = e => {
    const value = e.target.value;

    this.setState({ rawInput: value }, () => {
      this.updateWithInput(value);
    });

    if (!value) {
      this.setState({ canShowError: false });
    }
  }

  handleBlur = () => {
    this.updateWithInput(this.state.rawInput, true);
    this.setState({ canShowError: true });
  }

  updateWithInput = (input, updateRawInput = false) => {
    const num = parseSafe(input, this.state.selectedRegion);

    const numCountryCode = num && num.getCountryCode();
    const numRegion = num && phoneUtil.getRegionCodeForNumber(num);
    const numIsValid = num && this.isValid(num);

    // If we can glean a country code from the number, we might want
    // to change the selected region in the dropdown.
    if (numCountryCode) {
      const guessedRegionOption = regionOptions.find(opt => opt.code === numCountryCode);
      const guessedRegion = (
        guessedRegionOption
        && guessedRegionOption.code !== this.getCurrentCode()
        && guessedRegionOption.value
      );

      const newRegion = numRegion || guessedRegion;

      if (newRegion) {
        this.setState({ selectedRegion: newRegion });
      }
    }

    // Set the Formsy value of the input.
    if (numIsValid) {
      this.props.setValue(phoneUtil.format(num, PhoneNumberFormat.E164));

      if (updateRawInput) {
        this.setState({ rawInput: phoneUtil.format(num, PhoneNumberFormat.NATIONAL) });
      }
    } else {
      this.props.setValue(input);

      if (updateRawInput && input !== this.state.rawInput) {
        this.setState({ rawInput: input });
      }
    }
  }

  render() {
    const invalid = this.state.canShowError && (
      this.props.showError
      || !this.props.isValid && (this.props.validatePristine || !this.props.isPristine)
    );

    const inputClassname = classnames(
      'form-control',
      this.props.className,
      {
        'is-invalid': invalid,
        'newStyle': this.props.newStyle,
      },
    );

    return (
      <InputWrapper invalid={ invalid } { ...this.props }>
        <div className="phone-input-wrapper">
          <Select
            name="locale"
            noWrapper
            options={ regionOptions }
            components={ {
              Option: CustomOption,
              SingleValue: CustomSingleValue,
              IndicatorSeparator: null,
              Menu: CustomMenu,
            } }
            isSearchable={ false }
            onChange={ this.handleRegionChange }
            value={ this.state.selectedRegion }
            styles={ {
              menu: styles => {
                return { ...styles, width: 250 };
              },
            } }
          />
          <input
            type="tel"
            name={ this.props.name }
            onBlur={ this.handleBlur }
            onChange={ this.handleChange }
            value={ this.state.rawInput }
            className={ inputClassname }
          />
        </div>
      </InputWrapper>
    );
  }

}

export default withFormsy(PhoneInput);
