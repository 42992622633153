import { Component } from 'react';
import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';
import { Input as BootstrapInput } from 'reactstrap';
import MailCheck from 'react-mailcheck';

import { InputWrapper } from 'shared/components/form/inputs';

export class MailInput extends Component {

  state = {
    canShowError: false,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,

    // Only callback refs work. I'm sorry. It's Reactstrap's fault.
    getRef: PropTypes.func,

    // withFormsy props
    isValid: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
    value: PropTypes.any,
  };

  modifyValue = ({ target }) => {
    if (!target) {
      return;
    }

    const value = target.value;

    // If the field gets emptied out, turn off errors again
    // so they don't appear as the user types
    if (!value) {
      this.setState({ canShowError: false });
    }

    return this.props.setValue(value);
  };

  onBlur = () => {
    // If the field has been blurred, show error messages
    this.setState({ canShowError: true });
  };

  updateEmailSuggestion = ({ target }) => {
    return this.props.setValue(target.text);
  };

  renderInput(invalid) {

    const { name, value, inputProps, disabled } = this.props;
    return (
      <BootstrapInput
        id={ name }
        name={ name }
        type="email"
        invalid={ invalid }
        value={ value || '' }
        onBlur={ this.onBlur }
        onChange={ this.modifyValue }
        disabled={ disabled }
        innerRef={ this.props.getRef }
        { ...inputProps }
      />
    );
  }

  renderMailcheck() {
    return (
      <MailCheck email={ this.props.value || '' }>
        { suggestion => (
          <div>
            { suggestion &&
              <div className="form-control-feedback">
                Did you mean <a onClick={ this.updateEmailSuggestion } href="#">{ suggestion.full }</a>?
              </div>
            }
          </div>
        ) }
      </MailCheck>
    );
  }

  render() {
    const invalid = (this.props.showError || !this.props.isValid) &&
      (this.props.validatePristine || !this.props.isPristine) &&
      !(this.props.validateOnBlur && !this.state.canShowError);


    return (
      <InputWrapper
        invalid={ invalid }
        append={ this.renderMailcheck() }
        { ...this.props }
      >
        { this.renderInput(invalid) }
      </InputWrapper>
    );
  }

}

export default withFormsy(MailInput);
