import Account from 'data/account/model';
import { toEntityMap } from 'shared/util/toEntityMap';
import { ACCOUNTS_LIST } from 'store/actionTypes';
import EntityState from 'store/EntityState';

const initialState = new EntityState();

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNTS_LIST.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        error: null,
        receivedAt: 0,
      });
    case ACCOUNTS_LIST.SUCCESS:
      return state.merge({
        loading: action.loading,
        loaded: !action.loading,
        receivedAt: Date.now(),
        items: toEntityMap(action.payload, Account),
      });
    case ACCOUNTS_LIST.FAILURE:
      return state.merge({
        loading: true,
        loaded: false,
        error: action.payload,
        receivedAt: 0,
      });
    default:
      return state;
  }
}
