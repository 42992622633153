import { setUserId } from 'shared/auth';
import request from 'shared/request';

import { camelizeKeys, decamelizeKeys } from 'humps';
import Immutable from 'immutable';
import { isNull, omitBy } from 'lodash';

class User extends Immutable.Record({
  id: null,
  type: 'user',
  accountId: 0,
  role: 3,
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  updatedAt: null,
  createdAt: null,
  activated: null,
  loginId: null,
  isDeleted: null,
  isHidden: null,
}) {
  static Role = {
    ADMIN: 1,
    MANAGER: 2,
    SUPERVISOR: 5,
    EMPLOYEE: 3,
  };

  constructor(defaults) {
    super(camelizeKeys(defaults));
  }

  is(role) {
    return role === this.role;
  }

  isAccountHolder() {
    return this.is(User.Role.ADMIN);
  }

  isManager() {
    return this.is(User.Role.MANAGER);
  }

  isSupervisor() {
    return this.is(User.Role.SUPERVISOR);
  }

  isEmployee() {
    return this.is(User.Role.EMPLOYEE);
  }

  toSnake() {
    return decamelizeKeys(this.toJS());
  }

  roleName() {
    switch (this.role) {
      case 1:
        return 'Admin';
      case 2:
        return 'Manager';
      case 5:
        return 'Supervisor';
      case 3:
      default:
        return 'Employee';
    }
  }

  pending() {
    return !this.activated;
  }

  leave() {
    if (this.pending()) {
      return request('api').post('/users/leave', {
        user_id: this.id,
        account_id: this.accountId,
      });
    }
  }

  fullName() {
    if (!this.lastName) {
      return this.firstName;
    }
    return [this.firstName, this.lastName].join(' ');
  }

  static becomeUser(userId) {
    setUserId(userId);
  }

  static onboard(id) {
    return request('api').post(`/users/${id}/profile`, {
      is_onboarded: true,
    });
  }

  static update(user) {
    return request('api').put(`/users/${user.id}`, {
      ...omitBy(user.toSnake(), isNull),
    });
  }
}

export default User;
