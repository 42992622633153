import request from 'shared/request';
import { CREATE_SUBSCRIPTION } from 'store/actionTypes';
import { errorNotice } from 'wiw/notices';

export function createSubscriptionRequest(params = {}) {
  return {
    type: CREATE_SUBSCRIPTION.REQUEST,
    params,
  };
}

export function createSubscriptionFailed(error) {
  return {
    type: CREATE_SUBSCRIPTION.FAILURE,
    payload: error,
  };
}

export function createSubscriptionSuccess(payload = {}) {
  return {
    type: CREATE_SUBSCRIPTION.SUCCESS,
    payload,
  };
}

export function createSubscription(updateStore = true) {
  return dispatch => {
    dispatch(createSubscriptionRequest());
    return request('api')
      .post('/subscriptions/freetrial')
      .then(response => {
        return updateStore ? dispatch(createSubscriptionSuccess(response)) : response;
      })
      .catch(error => {
        dispatch(createSubscriptionFailed(error));
        dispatch(errorNotice(error.data.error));
        throw error;
      });
  };
}
