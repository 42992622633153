import { lazy } from 'react';

import EmptyLayout from 'shared/layouts/EmptyLayout';
import { FullscreenLayout } from 'shared/layouts/FullscreenLayout';
import MainLayout from 'shared/layouts/MainLayout';
import ReactivateLayout14 from 'shared/layouts/ReactivateLayout14';
import ReactivateLayout30 from 'shared/layouts/ReactivateLayout30';
import PageViewTracker from 'shared/middleware/PageViewTracker';
import Redirect from 'shared/util/router/Redirect';
import RedirectSearch from 'shared/util/router/RedirectSearch';
import GTMTracker from 'shared/vendor/GTMTracker';

import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

const Login = lazy(() => import('login/containers/Login'));
const SSO = lazy(() => import('./login/containers/SSO'));
const SAML = lazy(() => import('./login/containers/SAML'));
const Forgot = lazy(() => import('./login/containers/Forgot'));
const ResetPassword = lazy(() => import('./login/containers/ResetPassword'));
const ZendeskSSO = lazy(() => import('./login/containers/ZendeskSSO'));
const ChangePassword = lazy(() => import('./password/components/ChangePassword'));
const SetPassword = lazy(() => import('./password/components/SetPassword'));

const Accounts = lazy(() => import('./accounts/containers/Accounts'));
const Invites = lazy(() => import('./fre/components/Invites'));
const Register = lazy(() => import('./register/containers/Register'));
const OAuth = lazy(() => import('./oauth/containers/Oauth'));
const Use = lazy(() => import('./use'));

const MFASetup = lazy(() => import('./mfa/components/MFASetup'));
const MFAConfigure = lazy(() => import('./mfa/containers/MFAConfigure'));
const MFARegenerate = lazy(() => import('./mfa/components/MFARegenerate'));
const MFAConfirm = lazy(() => import('./mfa/containers/MFAConfirm'));
const AppInstall = lazy(() => import('./mfa/components/app/AppInstall'));
const AppSetup = lazy(() => import('./mfa/components/app/AppSetup'));
const AppSetupManual = lazy(() => import('./mfa/components/app/AppSetupManual'));
const AppConfirm = lazy(() => import('./mfa/components/app/AppConfirm'));
const AppRecovery = lazy(() => import('./mfa/components/app/AppRecovery'));
const SmsSetup = lazy(() => import('./mfa/components/sms/SmsSetup'));
const SmsConfirm = lazy(() => import('./mfa/components/sms/SmsConfirm'));
const SmsRecovery = lazy(() => import('./mfa/components/sms/SmsRecovery'));
const CreateWorkplace = lazy(() => import('./fre/components/CreateWorkplace'));
const Search = lazy(() => import('./search/containers/Search'));
const Join = lazy(() => import('./search/containers/Join'));
const JoinConfirm = lazy(() => import('./search/containers/JoinConfirm'));
const CreateAccount = lazy(() => import('./search/containers/CreateAccount'));
const CreatePlace = lazy(() => import('./search/containers/CreatePlace'));
const CreateSSOAccount = lazy(() => import('./search/components/CreateSSOForm'));
const Choose = lazy(() => import('register/components/Choose'));
const NoInvite = lazy(() => import('register/components/NoInvite'));

const Reactivate30Login = lazy(() => import('./reactivate/containers/Login30'));
const Reactivate30Forgot = lazy(() => import('./reactivate/containers/Forgot30'));
const Reactivate30ResetPassword = lazy(() => import('./reactivate/containers/Reset30'));

const Reactivate14Login = lazy(() => import('./reactivate/containers/Login14'));
const Reactivate14Forgot = lazy(() => import('./reactivate/containers/Forgot14'));
const Reactivate14ResetPassword = lazy(() => import('./reactivate/containers/Reset14'));

const LaunchDarklyDiagnostics = lazy(() => import('./diagnostics/components/LaunchDarklyDiagnostics'));

//pageName prop is used for tracking. It needs to be PascalCase.
//We manually added the labels because we don't want tracking labels to change if routes change.
//When we create a new route which the user is going to interact with the page we need to add this prop.
//Names for each route can be found here: https://docs.google.com/spreadsheets/d/1ATzF_ZzQRZtEwpTAbdPTfxdCW33okZ2k_x2-BQY3MYQ

export const history = createBrowserHistory();

function Routes() {
  return (
    <Router history={ history }>
      <GTMTracker />
      <PageViewTracker />
      <RedirectSearch search="redirect" value="#30-day-free-trial" to="/reactivate-30" />
      <Switch>
        <MainLayout path="/" exact component={ Login } auth={ false } pageName="Login" />

        <EmptyLayout path="/use" component={ Use } pageName="AutologinRedirect" />
        <EmptyLayout path="/zendesk" component={ ZendeskSSO } redirectBack pageName="ZendeskRedirect" />

        <MainLayout path="/connect" exact component={ SSO } auth={ false } back pageName="ConnectSSO" />
        <MainLayout path="/connect/:provider" component={ OAuth } auth={ false } pageName="SSOProviderConnect" />
        <MainLayout path="/sso" component={ SAML } auth={ false } back />

        <MainLayout path="/register" component={ Register } auth={ false } pageName="Signup" />

        <MainLayout path="/forgot" component={ Forgot } auth={ false } back pageName="ForgotPassword" />
        <MainLayout path="/reset" component={ ResetPassword } auth={ false } pageName="ResetPassword" />
        <MainLayout path="/password/change" component={ ChangePassword } pageName="ChangePassword" />
        <MainLayout path="/password/set" component={ SetPassword } auth={ false } authRedirect={ false } pageName="SetPassword" />

        <MainLayout path="/mfa/setup" exact component={ MFASetup } back logout pageName="SetupMFA" />
        <MainLayout path="/mfa/configure" exact component={ MFAConfigure } back logout pageName="ConfigureMFA" />
        <MainLayout path="/mfa/regenerate" exact component={ MFARegenerate } back logout pageName="RegenerateMFA" />
        <MainLayout path="/mfa/confirm" exact component={ MFAConfirm } back logout pageName="ConfirmMFA" />

        <MainLayout path="/mfa/app" exact component={ AppInstall } back pageName="MFAChooseApp" />
        <MainLayout path="/mfa/app/setup" exact component={ AppSetup } back pageName="MFASetupQRCode" />
        <MainLayout path="/mfa/app/setup/manual" exact component={ AppSetupManual } back pageName="MFASetupEnterCode" />
        <MainLayout path="/mfa/app/confirm" exact component={ AppConfirm } back pageName="MFAConfirm" />
        <MainLayout path="/mfa/app/recovery" exact component={ AppRecovery } back pageName="MFARecovery" />
        <MainLayout path="/mfa/sms" exact component={ SmsSetup } back pageName="MFASMSsetup" recaptchaEnabled />
        <MainLayout path="/mfa/sms/confirm" exact component={ SmsConfirm } back pageName="MFASMSconfirm" />
        <MainLayout path="/mfa/sms/recovery" exact component={ SmsRecovery } back pageName="MFASMSrecovery" />

        <Route path="/create/workplace">
          <FullscreenLayout pageName="FreCreateWorkplace">
            <CreateWorkplace />
          </FullscreenLayout>
        </Route>

        <MainLayout path="/accounts" component={ Accounts } logout pageName="AccountsList" />
        <MainLayout path="/search" component={ Search } back logout pageName="FindWorkplace" />
        <MainLayout path="/join/:accountId" component={ Join } back logout pageName="JoinWorkplace" />
        <MainLayout path="/join-confirm" component={ JoinConfirm } back logout pageName="JoinWorkplaceConfirmation" />
        <Route path="/create/invites">
          <FullscreenLayout pageName="Invites">
            <Invites />
          </FullscreenLayout>
        </Route>
        <MainLayout path="/create/account" component={ CreateAccount } back logout pageName="DescribeNewWorkplace" />
        <MainLayout path="/create/place" component={ CreatePlace } back logout pageName="CreateNewWorkplace" />
        <MainLayout path="/create/new" component={ CreateSSOAccount } logout pageName="CreateNewSSOWorkplace" />
        <MainLayout path="/choose" component={ Choose } logout pageName="Choose" />
        <MainLayout path="/no-invite" component={ NoInvite } back logout pageName="NoInvite" />

        <ReactivateLayout30 path="/reactivate-30" exact component={ Reactivate30Login } pageName="TrialActivate30" />
        <ReactivateLayout30 path="/reactivate-30/forgot-password" exact component={ Reactivate30Forgot } pageName="TrialActivate30ForgotPassword" />
        <ReactivateLayout30 path="/reactivate-30/forgot-password/reset" component={ Reactivate30ResetPassword } pageName="TrialActivate30ForgotPasswordReset" />

        <ReactivateLayout14 path="/reactivate-14" exact component={ Reactivate14Login } pageName="TrialReactivate14" />
        <ReactivateLayout14 path="/reactivate-14/forgot-password" exact component={ Reactivate14Forgot } pageName="TrialReactivate14ForgotPassword" />
        <ReactivateLayout14 path="/reactivate-14/forgot-password/reset" component={ Reactivate14ResetPassword } pageName="TrialReactivate14ForgotPasswordReset" />

        <MainLayout path="/test/launchdarkly" component={ LaunchDarklyDiagnostics } auth={ false } authRedirect={ false } pageName="LaunchDarklyDiagnostics" />

        <Redirect from="/profile" exact to="/accounts" passSearch />
        <Redirect from="/profile/find" to="/search" />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default Sentry.withProfiler(Routes);
