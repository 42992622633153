import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { withDialogContext } from 'wiw/dialogs/context';
import { NoticeList } from 'wiw/notices/components/list';

import classnames from 'classnames';

export class Dialog extends Component {

  div = createRef();

  state = {
    scrollable: false,
    initialHeight: 0,
  };

  static propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    style: PropTypes.object,
    children: PropTypes.node,
    loading: PropTypes.bool,
    loader: PropTypes.node,
    onBlur: PropTypes.func,
    // onDismiss is a function to call when the dialog is closing.
    // If it returns false, the dialog will not close. It receives
    // the keyEvent if the dismiss was caused by hitting Escape.
    // Pass { force: true } to close the dialog regardless.
    onDismiss: PropTypes.func,
    dismissable: PropTypes.bool,

    setOnDismiss: PropTypes.func,
  };

  static defaultProps = {
    width: 400,
    style: {},
    loading: false,
    loader: 'Loading...',
    dismissable: true,
  };

  onDocumentClick = event => {
    if (this.props.onBlur && this.div.current && this.div.current.parentElement == event.target) {
      this.props.onBlur();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentClick, true);

    const { setOnDismiss, onDismiss, dismissable } = this.props;
    if (onDismiss) {
      setOnDismiss(onDismiss);
    } else if (dismissable === false) {
      // Disable the dismiss of the dialog
      setOnDismiss(() => false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClick, true);
  }

  render() {
    const style = Object.assign({}, {
      width: `${ this.props.width }px`,
    }, this.props.style || {});

    return (
      <div
        className={ classnames('dialog-kit', this.props.className, {
          scrollable: this.state.scrollable,
        }) }
        style={ style }
        ref={ this.div }
        role="dialog"
      >
        <NoticeList dialog />
        { this.props.loading ? this.props.loader : this.props.children }
      </div>
    );
  }
}

export default withDialogContext(Dialog);
