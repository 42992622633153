import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import { v4 as uuidv4 } from 'uuid';
import { UncontrolledTooltip } from 'reactstrap';

export class Checkbox extends Component {

  targetId = `w${ uuidv4() }`;

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    labelSmall: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.bool,
    name: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    classNames: PropTypes.string,
    wrapperClasses: PropTypes.string,
    newStyle: PropTypes.bool,
    onChange: PropTypes.func,
    validatePristine: PropTypes.bool,
    inline: PropTypes.bool,
    tooltip: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.node,
    ]),
    tooltipPlacement: PropTypes.string,
    indeterminate: PropTypes.bool,
  };

  static defaultProps = {
    value: false,
    width: false,
    disabled: false,
    newStyle: false,
    validatePristine: false,
    indeterminate: false,
    inline: false,
    onChange: () => {
    },
    tooltip: false,
    tooltipPlacement: 'bottom',
  };

  componentDidMount() {
    if (this.props.value !== false) {
      this.props.setValue(this.props.value);
    }
  }

  modifyValue = e => {
    const value = e.target.checked;
    this.props.onChange(this.props.name, value);
    return this.props.setValue(value);
  };

  render() {
    const invalid = (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    const className = classnames(
      'form-group',
      'custom-checkbox',
      'custom-control',
      this.props.classNames,
      {
        'has-danger': invalid,
        'disabled': this.props.disabled,
        'newStyle': this.props.newStyle,
        'custom-control-inline': this.props.inline,
      },
    );

    const inputClassname = classnames(
      'form-check-input',
      'custom-control-input',
      this.props.inputClassName,
    );

    const checkedProps = {};
    if (this.props.uncontrolled) {
      checkedProps.defaultChecked = this.props.checked;
    } else {
      checkedProps.checked = this.props.checked;
    }

    const wrapperClasses = classnames(
      this.props.wrapperClasses,
      {
        'col': this.props.width !== null && !this.props.width,
        [`col-${ this.props.width }`]: this.props.width,
        'disabled': this.props.disabled,
        'newStyle': this.props.newStyle,
        'custom-control-inline': this.props.inline,
        'has-danger': invalid,
      },
    );

    return <Fragment>
      <div id={ this.targetId } className={ wrapperClasses }>
        <div className={ className }>
          <input
            type="checkbox"
            className={ inputClassname }
            id={ this.props.name }
            name={ this.props.name }
            checked={ this.props.value == true }
            { ...checkedProps }
            onChange={ this.modifyValue }
            disabled={ this.props.disabled }
            ref={ this.el }
          />
          <label className="custom-control-label" htmlFor={ this.props.name }>{ this.props.label }</label>
          { this.props.labelSmall && <small className="form-text text-muted">{ this.props.labelSmall }</small> }
          { invalid &&
            <div className="form-control-feedback">{ this.props.errorMessage }</div>
          }
        </div>
      </div>
      { this.props.tooltip && <UncontrolledTooltip target={ this.targetId } placement={ this.props.tooltipPlacement }>
        { this.props.tooltip }
      </UncontrolledTooltip> }
    </Fragment>;
  }
}

export default withFormsy(Checkbox);
