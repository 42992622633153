import { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

class Button extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'warning', 'danger', 'link', 'custom', 'green', 'red', 'orange', 'gray', 'blue', 'gold']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    iconOnly: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    block: PropTypes.bool,
    outline: PropTypes.bool,
    replace: PropTypes.bool,
    link: PropTypes.bool,
    style: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
    type: 'button',
    color: 'primary',
    size: 'md',
    loading: false,
    to: null,
    block: true,
    outline: false,
    replace: false,
    link: false,
  };

  renderLoadingState() {
    return (
      <div className="loader-inner ellipsis">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
  }

  renderLink(children) {
    const classes = classNames(
      'btn',
      `btn-${this.props.color}`,
      this.props.className,
      {
        [`btn-${this.props.size}`]: !!this.props.size,
        'btn-icon-only': this.props.iconOnly,
        working: this.props.loading,
        'btn-block': this.props.block,
        'btn-outline': this.props.outline,
        'btn-link': this.props.link,
      },
    );
    return (
      <Link to={ this.props.to } className={ classes } replace={ this.props.replace } onClick={ this.props.onClick }>{children}</Link>
    );
  }

  renderButton(children) {
    const classes = classNames(
      'btn',
      `btn-${this.props.color}`,
      this.props.className,
      {
        [`btn-${this.props.size}`]: !!this.props.size,
        'btn-icon-only': this.props.iconOnly,
        working: this.props.loading,
        'btn-block': this.props.block,
        'btn-outline': this.props.outline,
        'btn-link': this.props.link,
      },
    );

    return (
      <button
        type={ this.props.type }
        className={ classes }
        disabled={ this.props.disabled || this.props.loading }
        onClick={ this.props.onClick }
        style={ this.props.style || {} }
      >
        {children}
      </button>
    );
  }

  render() {
    const children = this.props.loading ? this.renderLoadingState() : this.props.children;
    return this.props.to ? this.renderLink(children) : this.renderButton(children);
  }
}

export default Button;
