import { Record } from 'immutable';

export default class FreeTrial extends Record({
  deniedReason: '',
  expiresDate: null,
  featureSet: null,
  renewalDate: null,
  type: null,
  status: null,
}) {

  static statusTypes = {
    ACTIVE: 'active',
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
  };

  isActive() {
    return this.status === FreeTrial.statusTypes.ACTIVE;
  }

  isAvailable() {
    return this.status === FreeTrial.statusTypes.AVAILABLE;
  }

  isUnavailable() {
    return this.status === FreeTrial.statusTypes.UNAVAILABLE;
  }

}
