import FontIcon from 'wiw/ui/FontIcon';

type CloseToastProps = {
  closeToast: () => void,
}
const CloseToast = ({ closeToast }:CloseToastProps) => (
  <button
    type="button"
    className="action-close-toast"
    onClick={ closeToast }
    aria-label="Dismiss"
  >
    <FontIcon icon="close" className="close-toast" />
  </button>);

export default CloseToast;
