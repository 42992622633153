import loadingAnimation from 'shared/assets/animations/loading.json';
import { Bodymovin } from 'shared/components';

import 'wiw/ui/loading/assets/styles/loading-icon.scss';

type LoadingProps = {
  className?: string
}
const Loading = ({ className }:LoadingProps) => {
  return <Bodymovin className={ `loading-icon ${className ?? ''}` } animationData={ loadingAnimation } />;
};

export default Loading;
