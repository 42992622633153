import PropTypes from 'prop-types';

import 'shared/assets/styles/googleMap.scss';
import locationIcon from 'shared/assets/map-location.svg';

const GoogleMap = ({
  latitude,
  longitude,
  options,
  style,
}) => {

  const settings = {
    width: 200,
    height: 150,
    zoom: 15,
    marker: 'small',
    color: null,
    ...options,
  };

  const generateUrl = () => {
    var base = [
      'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDnIy4H6lL24aD7VnYxc1hKYYJM1r63Qlo',
      '&size=', settings.width, 'x', settings.height,
      '&center=', latitude, ',', longitude,
      '&zoom=', settings.zoom,
      '&scale=', (window.devicePixelRatio || 1),
      '&sensor=false',
    ];

    return base.join('');
  };

  return (
    <div className="google-map">
      <img className="map" src={ generateUrl() } style={ style } />
      <img className="marker" src={ locationIcon } />
    </div>
  );
};

GoogleMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  options: PropTypes.object,
  style: PropTypes.object,
};

export default GoogleMap;
