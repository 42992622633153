import { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

export class DialogFooter extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    return (
      <div className={ classnames('dialog-footer', this.props.className) }>
        { this.props.children }
      </div>
    );
  }
}
