import { Component } from 'react';
import PropTypes from 'prop-types';

class Dialog extends Component {

  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,

    headerButtons: PropTypes.array,
    footerLeftButtons: PropTypes.array,
    footerRightButtons: PropTypes.array,
  }

  render() {
    return (
      <div className="dialog-container">
        <div className="dialog">
          <div className="dialog-header">
            <h3 className="dialog-title">{ this.props.title }</h3>
            <div className="dialog-header-buttons">
              { this.props.headerButtons }
            </div>
          </div>
          <div className="dialog-contents">
            { this.props.children }
          </div>
          <div className="dialog-footer">
            <div className="dialog-footer-buttons-left">
              { this.props.footerLeftButtons }
            </div>
            <div className="dialog-footer-buttons-right">
              { this.props.footerRightButtons }
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Dialog;
