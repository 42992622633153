import { Suspense } from 'react';
import PropTypes from 'prop-types';

import BrowserGate from 'shared/components/BrowserGate';
import PageContainer from 'shared/components/PageContainer';
import { Panel } from 'wiw/ui';
import LoadingApp from 'wiw/ui/loading/LoadingApp';

import { useHistory } from 'react-router-dom';

export default function EmptyLayout(props) {
  EmptyLayout.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    component: PropTypes.element,
    redirectBack: PropTypes.bool,
  };

  const history = useHistory();

  const { component: Component, location, ...rest } = props;

  return (
    <BrowserGate>
      <div id="wheniwork-is-awesome">
        <div id="content" className="content">
          <div className="content--inside">
            <PageContainer>
              <Panel>
                <Suspense fallback={
                  <div className="panel-loading">
                    <LoadingApp />
                  </div>
                }>
                </Suspense>
                <Component { ...rest } history={ history } location={ location } />
              </Panel>
            </PageContainer>
          </div>
        </div>
      </div>
    </BrowserGate>
  );
}
