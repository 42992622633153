import { Component } from 'react';
import PropTypes from 'prop-types';

import { InputWrapper } from 'shared/components/form/inputs';

import classnames from 'classnames';
import { withFormsy } from 'formsy-react';
import FontIcon from 'wiw/ui/FontIcon';

class SelectWithOptgroups extends Component {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,

    // withFormsy props
    isPristine: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    setValue: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
  };

  state = {
    open: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if ('value' in nextProps && (this.props.value !== nextProps.value)) {
      this.props.setValue(nextProps.value);
    }
  }

  getSelectedOption() {
    if (this.props.value === undefined || this.props.value === null) {
      return null;
    }

    const getOptionByValue = (options, value) => {
      for (const option of options) {
        if (option.value === value) {
          return option;
        } else if (option.options) {
          const recursiveResult = getOptionByValue(option.options, value);
          if (recursiveResult) {
            return recursiveResult;
          }
        }
      }

      return null;
    };

    return getOptionByValue(this.props.options, this.props.value);
  }

  onSelectedClicked = () => {
    this.setState({ open: !this.state.open });
  };

  onOptionClicked = option => {
    this.props.setValue(option.value);
    this.setState({
      open: false,
    });
  };

  renderOptions(options) {
    return options.map(option => {
      const elem = (
        <div
          className="option"
          data-value={ option.value }
          data-label={ option.label }
          onClick={ () => this.onOptionClicked(option) }
          key={ option.value }
        >
          { option.label }
        </div>
      );

      if (option.options) {
        return (
          <div className="optgroup" key={ option.value }>
            { elem }
            { this.renderOptions(option.options) }
          </div>
        );
      }

      return elem;
    });
  }

  renderSelected() {
    let label = 'Select...';
    const selected = this.getSelectedOption();
    if (selected) {
      label = selected.label;
    }

    return (
      <div
        className="selected"
      >
        { label }
      </div>
    );
  }

  renderChevron() {
    const icon = this.state.open ? 'chevron-up' : 'chevron-down';

    return (
      <div className="chevron">
        <FontIcon icon={ icon } />
      </div>
    );
  }

  render() {
    const invalid = (this.props.showError || !this.props.isValid) && !this.props.isPristine;

    const classes = classnames(
      'select-container',
      this.props.className,
      {
        // 'has-icon': this.props.icon,
        'placeholder': this.getSelectedOption() === null,
      },
    );

    return (

      <InputWrapper
        invalid={ invalid }
        inputGroupProps={ { style: { overflow: 'visible' } } }
        onClickOutside={ () => { this.setState({ open: false }); } }
        { ...this.props }
      >
        <div
          className={ classes }
          onClick={ this.onSelectedClicked }
        >
          { this.renderSelected() }
          { this.state.open &&
            <div className="options">
              { this.renderOptions(this.props.options) }
            </div>
          }
          { this.renderChevron() }
        </div>
      </InputWrapper>
    );
  }
}

export default withFormsy(SelectWithOptgroups);
