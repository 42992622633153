import { Fragment, PropsWithChildren } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type RecaptchaProps = {
  enabled?: boolean,
};

/**
 * A convenient wrapper for Recaptcha so it doesn't look so hideous in code.
 */
export function Recaptcha({ children, enabled = false }: PropsWithChildren<RecaptchaProps>) {
  if (enabled) {
    return <GoogleReCaptchaProvider reCaptchaKey={ CONFIG.RECAPTCHA_SITE_KEY } useEnterprise>
      {children}
    </GoogleReCaptchaProvider>;
  }

  return <Fragment>{children}</Fragment>;
}
