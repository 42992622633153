import { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

export class DialogBody extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.any,
  };

  getBoundHeights() {
    // check heights for header and footer
    const header = document.getElementsByClassName('dialog-header');
    const footer = document.getElementsByClassName('dialog-footer');
    const headerHeight = header.length > 0 ? header[0].clientHeight : 0;
    const footerHeight = footer.length > 0 ? footer[0].clientHeight : 0;
    return  headerHeight + footerHeight + 20;
  }

  render() {
    const style = Object.assign({}, {
      maxHeight: `calc(100vh - ${ this.getBoundHeights() }px`,
    }, this.props.style || {});

    return (
      <div className={ classnames('dialog-body', this.props.className) } style={ style }>
        { this.props.children }
      </div>
    );
  }
}
