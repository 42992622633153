
const allowedFields = [
  'name',
  'email',
  'redirect',
  'regid',
  'autofill',

  'return_to', // for Zendesk only
];

export function reduceSetEntryInfo(state = {}, action) {
  // Sanitize the info to put in the redux store
  let info = {};
  for (let key in action.info) {
    if (allowedFields.includes(key)) {
      info[key] = action.info[key];
    }
  }

  if (info.email) {
    let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!re.test(info.email)) {
      delete info.email;
    }
  }

  if (info.autofill) {
    try {
      info.autofill = JSON.parse(info.autofill);
    } catch (e) {
      delete info.autofill;
    }
  }
  state.entry = info;
}

export function reduceClearEntryInfo(state = {}, action) {
  state.entry = {};
}
