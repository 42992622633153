import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Header } from 'wiw/dialogs';
import Button from 'wiw/ui/Button';
import FontIcon from 'wiw/ui/FontIcon';

import classnames from 'classnames';
import { Record } from 'immutable';
import { partial } from 'lodash';

import 'wiw/dialogs/assets/styles/StepDialog.scss';

export class Step extends Record({
  id: '',
  label: '',
  component: null, // function returning a React component
  onActivated: null, // callback function(dialog, step) for when the step is selected
  hidden: false,
  locked: false,
}) {
}

export default class StepDialog extends Component {

  static propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    onBlur: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.instanceOf(Step)).isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number,
    currentStep: PropTypes.number.isRequired,
    locked: PropTypes.bool,
    setStep: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    closeDialog: PropTypes.func,
  };

  static defaultProps = {
    width: 720,
    locked: false,
  };

  getDefaultComponent = () => {
    if (this.props.closeDialog) {
      return (
        <Header>
          <Button
            size="sm"
            className="float-right"
            color="secondary"
            onClick={ this.props.closeDialog }
          ><FontIcon icon="close" /></Button>
        </Header>
      );
    }

    return null;
  };

  render() {
    const { className, loading, onBlur, steps, title, width, currentStep, setStep, locked, onDismiss } = this.props;
    const step = steps[currentStep];
    const numSteps = steps.reduce((accum, val) => accum + (val.hidden ? 0 : 1), 0);

    return (
      <Dialog
        className={ classnames('step-dialog', className, { 'no-steps': numSteps <= 1 }) }
        loading={ loading }
        onBlur={ onBlur }
        width={ width }
        onDismiss={ onDismiss }
      >
        <div className="step-container">
          <div className="row">
            {numSteps > 1 ? <div className="col-3 step-list">
              <div className="step-list-inner dialog-sticky">
                <h1>{ title }</h1>
                <ul>
                  {steps.map((step, idx) => {
                    if (step.hidden) {
                      return null;
                    }

                    const isComplete = idx < currentStep;
                    const iconClass = classnames({
                      'checkmark': isComplete,
                      'flag': isComplete,
                      'lock' : step.locked,
                    });

                    return (
                      <li key={ idx } className={ classnames({ complete: isComplete, current: idx === currentStep }) }>
                        <button
                          onClick={ isComplete && !step.locked && !locked ? partial(setStep, idx) : null }
                          disabled={ step.locked || locked || !isComplete }>
                          <FontIcon icon={ iconClass }>
                            <span>{ idx + 1 }</span>
                          </FontIcon>
                          { step.label }
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> : null}
            <div className="col step-dialog-body">
              { step && step.component ? step.component(this) : this.getDefaultComponent() }
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
