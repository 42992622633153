export default function generateThunkReducers(builder, sliceName) {
  return builder
    .addMatcher(action => action.type.startsWith(sliceName) && action.type.endsWith('/pending'),
      (state, action) => {
        return state.merge({
          loading: true,
          loaded: false,
          error: null,
          receivedAt: 0,
        });
      })
    .addMatcher(action => action.type.startsWith(sliceName) && action.type.endsWith('/rejected'),
      (state, action) => {
        return state.merge({
          loading: false,
          error: action.payload,
          receivedAt: Date.now(),
        });
      })
    .addMatcher(action => action.type.startsWith(sliceName) && action.type.endsWith('/fulfilled'),
      (state, action) => {
        return state.merge({
          loading: false,
          loaded: true,
          error: null,
          receivedAt: Date.now(),
        });
      })
    .addDefaultCase((state, action) => {
      return state;
    });
}
