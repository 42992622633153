export const BOOTSTRAP_INIT = 'BOOTSTRAP_INIT';
export const BOOTSTRAP_SUCCESS = 'BOOTSTRAP_SUCCESS';
export const BOOTSTRAP_FAILED = 'BOOTSTRAP_FAILED';
export const BOOTSTRAPPED = 'BOOTSTRAPPED';
export const EXIT_APP = 'EXIT_APP';

export const SET_ENTRY_INFO = 'SET_ENTRY_INFO';
export const CLEAR_ENTRY_INFO = 'CLEAR_ENTRY_INFO';

export const SET_LOGIN_DESTINATION = 'SET_LOGIN_DESTINATION';
export const CLEAR_LOGIN_DESTINATION = 'CLEAR_LOGIN_DESTINATION';

// Store all local action type constants here
/**
 * @typedef {Object} ReduxRequest
 * @property {string} REQUEST Fetch call requested
 * @property {string} SUCCESS Fetch call successful
 * @property {string} FAILURE Fetch call failed
 */
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

/**
 * DO NOT EDIT THIS FUNC
 * @param {string} base the request base name i.e. LOAD_ACCOUNTS
 * @return {ReduxRequest}
 */
export function createAsyncTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

export const LOGIN = createAsyncTypes('LOGIN');
export const LOGOUT = 'LOGOUT';
export const AUTOLOGIN = 'AUTOLOGIN';
export const REGISTER = createAsyncTypes('REGISTER');
export const LOAD_REGID_INFO = createAsyncTypes('LOAD_REGID_INFO');
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_MODE = 'SET_AUTH_MODE';

export const FORGOT_PASSWORD = createAsyncTypes('FORGOT_PASSWORD');
export const RESET_PASSWORD = createAsyncTypes('RESET_PASSWORD');
export const VERIFY_RESET_TOKEN = createAsyncTypes('VERIFY_RESET_TOKEN');
export const CHANGE_PASSWORD = createAsyncTypes('CHANGE_PASSWORD');
export const SET_PASSWORD = createAsyncTypes('SET_PASSWORD');

export const SET_TEMP_ACCOUNT = 'SET_TEMP_ACCOUNT';
export const SET_TEMP_USER = 'SET_TEMP_USER';
export const REQUEST_JOIN = createAsyncTypes('REQUEST_JOIN');
export const ONBOARD_UNCLAIMED_USER = createAsyncTypes('ONBOARD_UNCLAIMED_USER');
export const CREATE_UNCLAIMED_ACCOUNT = createAsyncTypes('CREATE_UNCLAIMED_ACCOUNT');
export const SUGGEST_INDUSTRY = createAsyncTypes('SUGGEST_INDUSTRY');
export const CHECK_PLACE_FREE = createAsyncTypes('CHECK_PLACE_FREE');
export const GET_TIMEZONES = createAsyncTypes('GET_TIMEZONES');

export const ACCOUNTS_LIST = createAsyncTypes('ACCOUNTS_LIST');
export const ACCOUNTS_SEARCH = createAsyncTypes('ACCOUNTS_SEARCH');
export const ACCOUNT_SELECT = 'ACCOUNT_SELECT';
export const SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT';

export const FETCH_USERS = createAsyncTypes('FETCH_USERS');
export const RECEIVE_USERS = createAsyncTypes('RECEIVE_USERS');
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';

export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_UPDATE = 'DIALOG_UPDATE';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';

// Notice
export const NOTICE_ADD = 'NOTICE_ADD';
export const NOTICE_REMOVE = 'NOTICE_REMOVE';
export const NOTICE_CLEAR = 'NOTICE_CLEAR';
export const NOTICE_TOAST = 'NOTICE_TOAST';
export const ROUTE_SET = 'ROUTE_SET';


export const FETCH_TRIAL_STATUS = createAsyncTypes('FETCH_TRIAL_STATUS');

export const FETCH_TIMEZONES = createAsyncTypes('FETCH_TIMEZONES');

export const CREATE_SUBSCRIPTION = createAsyncTypes('CREATE_SUBSCRIPTION');
