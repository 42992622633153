import autoSelectAccount from 'data/account/actions/autoSelectAccount';
import fetchAccounts from 'data/account/actions/fetchAccounts';
import authSlice from 'data/auth';
import fetchAuthInfo from 'data/auth/actions/fetchAuthInfo';
import Bootstrap from 'data/bootstrap';
import personSlice from 'data/person';
import setActiveUser from 'data/user/actions/setActiveUser';
import { AUTH_MODE, deleteUserId, getTokenInfo, getUserId } from 'shared/auth';
import { getAuthUser } from 'shared/auth/selectors';
import Identify from 'shared/vendor/identify';
import { exitApp } from 'store/actions/app';
import { loadEntryInfoAction } from 'store/actions/entryInfo';

import queryString from 'query-string';

/**
 * @param {string|null|undefined} token
 */
export function bootstrap(token = null, history) {
  return (dispatch, getStore) => {
    dispatch(Bootstrap.actions.start());

    dispatch(loadEntryInfoAction());

    if (!token) {
      Identify.identifyThirdPartyNoUserOrAccount(null, dispatch);
      return dispatch(Bootstrap.actions.complete());
    }

    const tokenInfo = getTokenInfo(token);

    // SAML users have a token without a pid and users as an array. These users have no person, but should still be
    // able to get to /accounts etc., so we should let them through the boostrapper and load accounts.
    if (tokenInfo?.acct && Array.isArray(tokenInfo?.user) && tokenInfo?.pid === '') {
      return dispatch(fetchAccounts())
        .then(() => {
          const store = getStore();
          const { bootstrap } = store;
          const authUser = getAuthUser(store);

          dispatch(setActiveUser(getUserId()));
          dispatch(autoSelectAccount());

          if (getUserId() && bootstrap.entry.redirect) {
            // They want to be redirected, and they're already logged in, with a user selected!
            // Take 'em away...but only if the selected user is valid.
            if (authUser) {
              dispatch(exitApp());
            } else {
              deleteUserId();
              dispatch(Bootstrap.actions.complete());
            }
          }

          dispatch(Bootstrap.actions.complete());
        })
        .catch(() => {
          dispatch(Bootstrap.actions.failed('Failed to fetch accounts'));
        });
    }

    return dispatch(fetchAuthInfo())
      .unwrap()
      .then(response => {

        const { auth_mode } = queryString.parse(history.location.search);
        if (auth_mode === 'sso') {
          dispatch(authSlice.actions.setAuthMode(AUTH_MODE.SSO));
        }

        if (tokenInfo.mfa === true) {
          if (history.location.pathname !== 'mfa/confirm') {
            dispatch(personSlice.actions.receivePerson(response.person, false));
            dispatch(authSlice.actions.setAuthMode(AUTH_MODE.MFA));
            dispatch(Bootstrap.actions.complete());
            return history.push('/mfa/confirm');
          }
        } else {
          dispatch(personSlice.actions.receivePerson(response.person));
        }

        dispatch(fetchAccounts())
          .then(() => {
            const store = getStore();
            const { bootstrap } = store;
            const authUser = getAuthUser(store);

            dispatch(setActiveUser(getUserId()));
            dispatch(autoSelectAccount());

            if (getUserId() && bootstrap.entry.redirect) {
              // They want to be redirected, and they're already logged in, with a user selected!
              // Take 'em away...but only if the selected user is valid.
              if (authUser) {
                dispatch(exitApp());
              } else {
                deleteUserId();
                dispatch(Bootstrap.actions.complete());
              }
            }

            dispatch(Bootstrap.actions.complete());
          })
          .catch(() => {
            dispatch(Bootstrap.actions.failed('Failed to fetch accounts'));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(Bootstrap.actions.complete());
      });
  };
}
