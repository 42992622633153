import { PropsWithChildren, Suspense } from 'react';
import { useSelector } from 'react-redux';

import BrowserGate from 'shared/components/BrowserGate';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import { Recaptcha } from 'shared/components/Recaptcha';
import { DevPanel } from 'shared/dev/containers/DevPanel';
import { useAuthRoutine } from 'shared/hooks/useAuthRoutine';
import { useWhiteBg } from 'shared/hooks/useWhiteBg';
import { trackClick } from 'shared/util/tracking/mercury';
import isBootstrapped from 'store/selectors/isBootstrapped';
import DialogContainer from 'wiw/dialogs/containers/DialogContainer';
import { NoticeList } from 'wiw/notices/components/list';
import Button from 'wiw/ui/Button';
import LoadingApp from 'wiw/ui/loading/LoadingApp';

import { useRouteMatch } from 'react-router-dom';

import 'shared/assets/styles/FullscreenLayout.scss';
import Logo from 'shared/assets/wiw-logo.svg';

type FullscreenLayoutProps = {
  auth?: boolean,
  authRedirect?: boolean,
  pageName: string,
  recaptchaEnabled?: boolean,
  redirectBack?: string,
};

export function FullscreenLayout({
  auth = true,
  authRedirect = true,
  children,
  pageName,
  recaptchaEnabled,
  redirectBack,
}: PropsWithChildren<FullscreenLayoutProps>) {
  const bootstrapped = useSelector(isBootstrapped);
  const match = useRouteMatch();

  useWhiteBg(true);
  useAuthRoutine({ needsAuth: auth, authRedirect, redirectBack });

  if (!bootstrapped) {
    return <LoadingApp />;
  }

  const routeClassName = match.path.split('/')
    .filter(seg => seg.length && !seg.startsWith(':'))
    .join('-');

  return <BrowserGate>
    <div id="wiw-fullscreen">
      <header>
        <img src={ Logo } alt="When I Work" className="wiw-logo" />
      </header>

      <main className={ routeClassName } data-view={ pageName }>
        <ErrorBoundary fallback={ <ErrorFallback /> }>
          <Suspense fallback={ <LoadingApp /> }>
            <Recaptcha enabled={ recaptchaEnabled }>
              { children }
            </Recaptcha>
          </Suspense>
        </ErrorBoundary>
      </main>
    </div>

    <NoticeList maxVisible={ 5 } followWindow />
    <DevPanel />
    <DialogContainer />
  </BrowserGate>;
}

function ErrorFallback() {
  function refresh() {
    trackClick('errorBoundaryRefresh', {}, {}, 'web::onInteraction', '#wiw-fullscreen > main');
    window.location.reload();
  }

  return <div className="wiw-fullscreen-error">
    <h1>Oops, something went wrong.</h1>
    <h6>Try reloading the page.</h6>
    <Button size="lg" onClick={ refresh }>Refresh</Button>
  </div>;
}
