import fetchIndustries from 'data/industries/actions/fetchIndustries';
import Industries from 'data/industries/model';
import { SUGGEST_INDUSTRY } from 'store/actionTypes';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  suggestions: [],
  industries: [],
};

export const industriesSlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        const industries = action.payload.map(industry => {
          const parent = new Industries(industry);
          if ('children' in industry && industry.children.length) {
            const children = industry.children.map(child => new Industries(child));
            return parent.set('options', children);
          }

          return parent;
        });
        return {
          ...state,
          industries: industries,
        };
      })
      .addCase(SUGGEST_INDUSTRY.SUCCESS, (state, action) => {
        return {
          ...state,
          suggestions: action.suggestions.map(suggestion => new Industries(suggestion)),
          industries: action.industries.map(industry => {
            const parent = new Industries(industry);
            if ('children' in industry && industry.children.length) {
              const children = industry.children.map(child => new Industries(child));
              return parent.set('options', children);
            }

            return parent;
          }),
        };
      });
  },
});
export default industriesSlice;
