import { useEffect, useRef } from 'react';

import FontIcon from 'wiw/ui/FontIcon';

type DialogCloseButtonProps = {
  onClose: () => void
  className?: string
  setFocus?: boolean
}

export const DialogCloseButton = ({ onClose, setFocus, className }: DialogCloseButtonProps) => {

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setFocus && buttonRef.current?.focus();
  }, []);

  const handleClose = () => onClose();

  return  <button
    type="button"
    ref={ buttonRef }
    className={ `dialog-button icon button-close ${className || ''}` }
    aria-label="Close"
    onClick={ handleClose }
  >
    <FontIcon icon="close" />
  </button>;
};
