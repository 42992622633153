import { CLEAR_ENTRY_INFO, SET_ENTRY_INFO } from 'store/actionTypes';

import Cookies from 'js-cookie';
import { pickBy } from 'lodash';
import QueryString from 'query-string';

const ENTRY_INFO_KEY = 'wiw-login-entry-info';

const allowedDomains = [
  CONFIG.ROOT_DOMAIN,
];

/**
 * Sanitizes the redirect URL to only allow certain domains,
 * and removes any default paths from overwriting real redirects
 *
 * @param {string} url
 * @returns
 */
function sanitizeRedirectURL(url) {
  if (!url) {
    return null;
  }
  // Parse the URL using the default app legacy host
  const parse = new URL(url, CONFIG.APP_LEGACY_ROOT);

  // Only allow certain domains, or return no redirect
  const allowed = allowedDomains.some(domain => parse.host.endsWith(`.${domain}`) || parse.host === domain);
  if (!allowed) {
    return null;
  }

  // Remove default redirect to the app dashboard
  if (
    parse.pathname === '/' &&
    parse.host.startsWith('app') &&
    parse.hash === ''
  ) {
    return null;
  }

  // Return the full URL including a host
  return parse.href;
}

export function loadEntryInfoAction() {
  return dispatch => {
    const query = QueryString.parse(window.location.search);
    const entryInfo = pickBy({
      ...query,
      // Sanitize the redirect, prioritizing the one in the URL
      redirect: sanitizeRedirectURL(query.redirect || Cookies.get('W-Redirect')),
    });

    const stringInfo = window.sessionStorage.getItem(ENTRY_INFO_KEY);
    let info = JSON.parse(stringInfo) || {};

    if (entryInfo && Object.keys(entryInfo).length > 0) {
      info = {
        ...info,
        ...entryInfo,
      };
      window.sessionStorage.setItem(ENTRY_INFO_KEY, JSON.stringify(info));
    }

    if (Object.keys(info).length > 0) {
      return dispatch({ type: SET_ENTRY_INFO, info });
    }
  };
}

export function clearEntryInfoAction() {
  return dispatch => {
    sessionStorage.removeItem(ENTRY_INFO_KEY);
    dispatch({ type: CLEAR_ENTRY_INFO });
  };
}
