import getStateAccounts from 'data/account/selectors/getStateAccounts';
import authSlice from 'data/auth/index';
import { getTokenAccountId, isUserToken, setToken } from 'shared/auth';
import request from 'shared/request';

export default accountId => (dispatch, getStore) => {
  accountId = parseInt(accountId, 10);
  const store = getStore();
  const accounts = getStateAccounts(store);

  // if the token presented is already a user token and the account matches the requested account, dont reenter account
  if (isUserToken() && getTokenAccountId() === `${ accountId }`) {
    if (accounts.has(accountId)) {
      return Promise.resolve(dispatch(authSlice.actions.setAuthAccount(accountId)));
    }
  }
  return request('login').get(`/enter/${ accountId }`).then(response => {
    setToken(response.token);
  })
    .catch(error => {
      const errorMessage = 'There was an error logging in.';
      throw new Error(errorMessage);
    })
    .finally(() => {
      if (accounts.has(accountId)) {
        return dispatch(authSlice.actions.setAuthAccount(accountId));
      }
    });
  // ensure the requested account is available to the user

};

