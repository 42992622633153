import request from 'shared/request';
import { FETCH_TRIAL_STATUS } from 'store/actionTypes';
import { errorNotice } from 'wiw/notices';

export function fetchTrialRequest(params = {}) {
  return {
    type: FETCH_TRIAL_STATUS.REQUEST,
    params,
  };
}

export function fetchTrialFailed(error) {
  return {
    type: FETCH_TRIAL_STATUS.FAILURE,
    payload: error,
  };
}

export function fetchTrialSuccess(payload = {}) {
  return {
    type: FETCH_TRIAL_STATUS.SUCCESS,
    payload,
  };
}

export function fetchTrialStatus() {
  return dispatch => {
    dispatch(fetchTrialRequest());
    return request('api')
      .get('/subscriptions/freetrial')
      .then(response => dispatch(fetchTrialSuccess(response)))
      .catch(error => {
        dispatch(fetchTrialFailed(error));
        dispatch(errorNotice(error.data.error));
        throw error;
      });
  };
}
