import fetchAuthInfo from 'data/auth/actions/fetchAuthInfo';
import login from 'data/auth/actions/login';
import personSlice from 'data/person';
import register from 'register/actions/register';
import { AUTH_MODE } from 'shared/auth/constants';
import { AUTOLOGIN } from 'store/actionTypes';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  mode: AUTH_MODE.DEFAULT,
  userId: null,
  accountId: null,
  personId: null,
  loaded: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthAccount: (state, action) => {
      state.accountId = action.payload;
    },
    setAuthMode: (state, action) => {
      state.mode = action.payload;
    },
    setAuthUser: (state, action) => {
      state.userId = action.payload;
      state.loggedIn = true;
    },
    logout: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, state => {
        state.mode = AUTH_MODE.NORMAL;
      })
      .addCase(register.fulfilled, state => {
        state.mode = AUTH_MODE.REGISTER;
      })
      .addCase(AUTOLOGIN, state => {
        state.mode = AUTH_MODE.AUTOLOGIN;
      })
      .addCase(personSlice.actions.receivePerson, (state, action) => {
        state.personId = action.payload.id;
        state.loggedIn = true;
      })
      .addCase(fetchAuthInfo.fulfilled, (state, action) => {
        state.personId = action.payload.person.id;
        state.loggedIn = true;
      })
      .addCase(authSlice.actions.setAuthAccount, (state, action) => {
        state.accountId = action.payload;
      });
  },
});

export const { setAuthAccount, setAuthMode, setAuthUser } = authSlice.actions;
export default authSlice;
