import Timezones from 'data/timezones/model';
import { toEntityMap } from 'shared/util/toEntityMap';
import { FETCH_TIMEZONES } from 'store/actionTypes';
import EntityState from 'store/EntityState';

const initialState = new EntityState();

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TIMEZONES.FAILURE:
      return state.merge({
        error: action.payload.error,
        loading: false,
        loaded: false,
      });
    case FETCH_TIMEZONES.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
      });
    case FETCH_TIMEZONES.SUCCESS:
      return state.merge({
        loading: false,
        loaded: true,
        items: toEntityMap(action.timezones, Timezones),
      });
    default:
      return state;
  }
}
