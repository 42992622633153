import ChangeMFAMethodDialog from 'mfa/dialogs/ChangeMFAMethodDialog';
import DisableMFADialog from 'mfa/dialogs/DisableMFADialog';
import RegenerateRecoveryDialog from 'mfa/dialogs/RegenerateRecoveryDialog';

export const CHANGE_MFA_METHOD_DIALOG = 'CHANGE_MFA_METHOD_DIALOG';
export const REGENERATE_RECOVERY_CODES_DIALOG = 'REGENERATE_RECOVERY_CODES_DIALOG';
export const DISABLE_MFA_DIALOG = 'DISABLE_MFA_DIALOG';

const dialogs = {
  [CHANGE_MFA_METHOD_DIALOG]: ChangeMFAMethodDialog,
  [REGENERATE_RECOVERY_CODES_DIALOG]: RegenerateRecoveryDialog,
  [DISABLE_MFA_DIALOG]: DisableMFADialog,
// Add more here ...
};

export default dialogs;
