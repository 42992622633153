import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

function setCookie(name, value) {
  return Cookies.set(name, value, {
    domain: `.${CONFIG.ROOT_DOMAIN}`,
    secure: true,
    sameSite: 'Lax',
  });
}

export function removeCookie(name) {
  return Cookies.remove(name, { domain: `.${CONFIG.ROOT_DOMAIN}` });
}

export const getToken = function () {
  return Cookies.get('W-Token');
};

export const setToken = function (token) {
  return setCookie('W-Token', token);
};

export const deleteToken = function () {
  return removeCookie('W-Token');
};

export const getTokenInfo = function () {
  return jwtDecode(getToken() || {});
};

export const isUserToken = () => {
  return getTokenAccountId() !== null && getTokenUserId() !== null;
};

export const getTokenAccountId = () => {
  const claims = getTokenInfo();
  return claims.aid || null;
};

export const getTokenUserId = () => {
  const claims = getTokenInfo();
  return claims.uid || null;
};

export const getUserId = function () {
  return Cookies.get('W-UserId');
};

export const getAnonymousUserId = function () {
  return Cookies.get('ld_anon_context');
};

export const setUserId = function (userId) {
  return setCookie('W-UserId', userId);
};

export const deleteUserId = function () {
  return removeCookie('W-UserId');
};

export const setStepUpToken = function (token) {
  return setCookie('W-StepUpToken', token);
};
