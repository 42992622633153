import { Redirect } from 'react-router-dom';

type RedirectSearchProps = {
  exact: boolean,
  search: string,
  to: string,
  value: string,
}

const RedirectSearch = ({ exact, search, to, value }: RedirectSearchProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const searchValue = urlParams.get(search) || '';

  if ((exact && value === searchValue) || (!exact && searchValue.indexOf(value) >= 0)) {
    return <Redirect to={ to } />;
  }

  return null;
};

export default RedirectSearch;
