import { List, Map } from 'immutable';

export function toEntityMap(items = [], model, key = 'id') {
  if (!Array.isArray(items) && !List.isList(items)) {
    items = [items];
  }
  return new Map(items.map(item => [item[key], new model(item)]));
}

export function toEntityArray(items = [], model) {
  if (!Array.isArray(items) && !List.isList(items) && !Map.isMap(items)) {
    items = [items];
  }

  if (typeof items.toArray === 'function') {
    items = items.toArray();
  }

  return items.map(item => new model(item));
}
