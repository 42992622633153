import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import InputWrapper from 'shared/components/form/inputs/InputWrapper';
import FontIcon from 'wiw/ui/FontIcon';

import classnames from 'classnames';
import { withFormsy } from 'formsy-react';
import { isImmutable } from 'immutable';
import { merge, omit } from 'lodash';
import ReactSelect, { components } from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';

class Select extends Component {
  inputRef = createRef();

  SelectType = {
    select: ReactSelect,
    creatable: Creatable,
    async: Async,
    asyncCreatable: AsyncCreatable,
  };

  static propTypes = {
    ...ReactSelect.propTypes,
    type: PropTypes.string,
    selectRef: PropTypes.any,
    setFocus: PropTypes.bool,
    renderOptionAddOns: PropTypes.bool,
    noWrapper: PropTypes.bool,
  };

  static defaultProps = {
    getOptionValue: option => option.value,
    type: 'select',
    setFocus: false,
    renderOptionAddOns: false,
    noWrapper: false,
  };

  componentDidMount() {
    if (this.props.setFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  getValue() {
    const { value, options } = this.props;
    let selectOptions = options;

    if (isImmutable(options)) {
      selectOptions = options.toArray();
    }

    if (value === null || typeof value === 'undefined') {
      return '';
    }

    if (Array.isArray(value)) {
      return value.map(value => selectOptions.find(option => {
        const optionValue = this.props.getOptionValue(option);
        return (optionValue == value || optionValue == value.value);
      }));
    }

    if (typeof value === 'object') {
      return value;
    }

    return this.props.options.find(option => option.value == value);
  }

  handleChange = option => {
    if (this.props.isMulti) {
      this.props.setValue(option);
      this.props.onChange?.(this.props.name, option);
    } else {
      const value = (option && option.hasOwnProperty('value')) ? option.value : null;
      this.props.setValue(value);
      this.props.onChange?.(this.props.name, value);
    }
  };

  onBlur = () => this.setState({ focus: false });

  onFocus = () => this.setState({ focus: true });

  renderOption = props => {
    const newProps = {
      className: props.data.className ? props.data.className : null,
      ...props,
    };

    if (this.props.renderOptionAddOns) {
      return <components.Option { ...newProps }>
        { props.label }
        { props.data.addOn && <span className="pull-right">{ props.data.addOn }</span> }
      </components.Option>;
    }
    return <components.Option { ...newProps } />;
  };

  renderClearIndicator = props => {
    return (<components.ClearIndicator { ...props }>
      <FontIcon icon="close" />
    </components.ClearIndicator>);
  };

  renderInput = invalid => {

    const inputClassname = classnames(
      this.props.className,
      'Select',
      {
        'form-control-danger': invalid,
      },
    );

    const SelectComponent = this.SelectType[this.props.type];
    const options = isImmutable(this.props.options) ? this.props.options.toArray() : this.props.options;

    const selectProps = omit(this.props, ['label', 'name', 'onChange', 'value', 'creatable', 'required']);

    // automatically detect if a dialog is open to portal selects
    const dialogKit = document.querySelector('.dialog-kit');

    if (this.props.isInDialog || dialogKit) {
      selectProps.menuPortalTarget = dialogKit;
      selectProps.menuPosition = 'fixed';
      selectProps.styles = { menuPortal: base => ({ ...base, zIndex: 200 }) };
    }

    return (<SelectComponent
      { ...selectProps }
      options={ options }
      ref={ this.props.setFocus ? this.inputRef : this.props.selectRef }
      components={ merge(
        {
          Option: this.renderOption,
          ClearIndicator: this.renderClearIndicator,
        },
        this.props.components)
      }
      clearable={ this.props.clearable }
      isDisabled={ this.props.isFormDisabled || this.props.disabled || this.props.isDisabled }
      onChange={ this.handleChange }
      onFocus={ this.onFocus }
      onBlur={ this.onBlur }
      classNamePrefix="Select"
      className={ inputClassname }
      value={ this.getValue() }
      setFocus={ this.props.setFocus }
    />);
  };


  render() {

    const invalid = (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    if (this.props.noWrapper) {
      return this.renderInput(invalid);
    }

    return (
      <InputWrapper
        { ...this.props }
        focus={ this.state?.focus }
      >
        { this.renderInput(invalid) }
      </InputWrapper>
    );
  }
}

export default withFormsy(Select);
