import defaultRequestConfig from 'shared/api/defaultRequestConfig';
import { ErrorResponse, ErrorResponseGoSingle, ErrorResponseMany, ErrorResponseOne } from 'shared/api/util/errors';
import toAxiosConfig from './toAxiosConfig';

import axios from 'axios';
import Cookies from 'js-cookie';
import { defaultsDeep } from 'lodash';
import qs from 'qs';

function buildQueryString(obj) {
  return Object.keys(obj)
    .map(key => `${ encodeURIComponent(key) }=${ encodeURIComponent(obj[key]) }`)
    .join('&')
    .replace(/%20/g, '+');
}

export default class AxiosRequest {
  // instance: Axios;
  defaultConfig;

  constructor(options = {}) {
    const config = toAxiosConfig(options);
    this.defaultConfig = {
      baseURL: CONFIG.API_ROOT,
      ...config,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
      headers: {
        'W-Date-Format': 'iso',
        ...config.headers,
      },
    };
  }

  get(url, opts = {}) {
    const config = toAxiosConfig(opts);

    config.url = url;
    config.method = 'GET';

    return this.send(config, opts.rawResponse);
  }

  post(url, data = {}, opts = {}) {
    const config = toAxiosConfig(opts);

    config.url = url;
    config.method = 'POST';
    config.data = JSON.stringify(data);

    return this.send(config, opts.rawResponse);
  }

  put(url, data = {}, opts = {}) {
    const config = toAxiosConfig(opts);

    config.url = url;
    config.method = 'PUT';
    config.data = JSON.stringify(data);

    return this.send(config, opts.rawResponse);
  }

  patch(url, data = {}, opts = {}) {
    const config = toAxiosConfig(opts);

    config.url = url;
    config.method = 'PATCH';
    config.data = JSON.stringify(data);

    return this.send(config, opts.rawResponse);
  }

  delete(url, opts = {}) {
    const config = toAxiosConfig(opts);

    config.url = url;
    config.method = 'DELETE';
    if (opts.data) {
      config.data = JSON.stringify(opts.data);
    }

    return this.send(config, opts.rawResponse);
  }

  upload(url, data = {}, opts = {}) {
    const config = toAxiosConfig(opts);

    config.url = url;
    config.method = 'POST';
    config.data = data;

    return this.send(config, opts.rawResponse);
  }

  /**
   * @returns {import('axios').AxiosPromise}
   */
  send(config = {}, rawResponse = false) {
    if (CONFIG.DEVPANEL.passBranchCookie) {
      config.headers = config.headers ?? {};
      config.headers['branch'] = Cookies.get('branch') || 'master';
    }

    if (CONFIG.DEVPANEL.passXdebugCookie && Cookies.get('XDEBUG_SESSION')) {
      config.params = {
        ...config.params,
        XDEBUG_SESSION_START: Cookies.get('XDEBUG_SESSION'),
      };
    }

    let url = config.url;
    if (!url) {
      throw new Error('Please include a `url` option for each request.');
    }

    if (url[0] !== '/') {
      throw new Error(`Please prefix your 'url' option with '/' (got '${ url }')`);
    }

    delete config.url;

    if (url.search(/^http/i) === 0) {
      delete config.baseURL;
    }

    if (config.params) {
      let prefix = '?';
      if (url.indexOf('?') > -1) {
        prefix = '&';
      }

      url += `${ prefix }${ buildQueryString(config.params) }`;
      delete config.params;
    }

    return axios(defaultsDeep({ url }, config, this.defaultConfig, defaultRequestConfig()))
      .catch(this.buildError);
  }

  buildError(error) {
    if (error.response) {
      const body = error.response.data;
      if (body != null && typeof body === 'object' && body.errors && Array.isArray(body.errors)) {
        if (body.errors.length === 1) {
          throw new ErrorResponseGoSingle(error.response, body.errors[0]);
        }
        throw new ErrorResponseMany(error.response, body);
      } else if (body != null && typeof body === 'object' && typeof body.error === 'string') {
        throw new ErrorResponseOne(error.response, body);
      } else {
        throw new ErrorResponse(error.response, body);
      }
    } else {
      throw new Error(error);
    }
  }
}

