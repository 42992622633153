import { deleteToken, deleteUserId } from 'shared/auth';
import { LOGOUT } from 'store/actionTypes';

export function attemptLogout() {
  return dispatch => {
    deleteToken();
    deleteUserId();
    dispatch({
      type: LOGOUT,
    });
  };
}

