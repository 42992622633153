import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'wiw/ui/Button';
import Form from 'shared/components/form';
import { Dialog, Header, Body, Footer } from 'wiw/dialogs';

import classnames from 'classnames';

export const CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG';

export default class ConfirmationDialog extends Component {

  state = {
    loading: false,
  };

  static propTypes = {
    className: PropTypes.string,
    closeDialog: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onBlur: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    confirmClass: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    buttonSize: PropTypes.string,
    waitToClose: PropTypes.bool,
    width: PropTypes.number,
    setFocus: PropTypes.bool,
  };

  static defaultProps = {
    body: 'Are you sure?',
    confirmLabel: 'Okay',
    cancelLabel: 'Cancel',
    buttonSize: 'sm',
    waitToClose: false,
    setFocus: false,
  };

  onDismiss = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  };

  handleConfirm = data => {
    if (this.props.waitToClose) {
      this.setState({ loading: true });
      return Promise.resolve(this.props.onConfirm(data))
        .then(() => {
          this.setState({ loading: false });
          this.props.closeDialog({ force: true });
        });
    }

    this.props.onConfirm(data);
    this.props.closeDialog({ force: true });
  };

  render() {
    const { title, body, className, confirmClass, confirmLabel, buttonSize, cancelLabel } = this.props;

    return (
      <Dialog className={ className } onDismiss={ this.onDismiss } style={ this.props.style } onBlur={ this.props.onBlur } width={ this.props.width }>
        <Form onValidSubmit={ this.handleConfirm }>
          <Header onClose={ this.props.closeDialog }>
            { title }
          </Header>
          <Body>
            <div>
              { body }
            </div>
          </Body>
          <Footer>
            <Button size={ buttonSize } type="submit" className={ classnames('pull-right ml-2', confirmClass) } loading={ this.state.loading } setFocus={ this.props.setFocus }>
              { confirmLabel }
            </Button>
            <Button size={ buttonSize } color="secondary" className="pull-right ml-2 cancel" onClick={ this.props.closeDialog }>
              { cancelLabel }
            </Button>
          </Footer>
        </Form>
      </Dialog>
    );
  }
}
