import { NOTICE_ADD, NOTICE_CLEAR, NOTICE_REMOVE, NOTICE_TOAST, ROUTE_SET } from 'store/actionTypes';

import classnames from 'classnames';
import crc32 from 'crc/lib/es6/crc32';
import uniqueId from 'lodash/uniqueId';
import { renderToStaticMarkup } from 'react-dom/server';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';

export const NOTICE_SUCCESS = 'success';
export const NOTICE_ERROR = 'error';
export const NOTICE_INFO = 'info';
export const NOTICE_WARNING = 'warning';

export function removeNotice(noticeId) {
  return {
    noticeId,
    type: NOTICE_REMOVE,
  };
}

export function clearNotices() {
  toast.dismiss();
  return {
    type: NOTICE_CLEAR,
  };
}

export function buildNotice(options = {}) {
  const notice = {
    noticeId: uniqueId(),
    duration: 3,
    showDismiss: true,
    animation: true,
    isPersistent: false,
    displayOnNextPage: false,
    title: null,
  };

  return { ...notice, ...options };
}

function sendToast(status, text, notice) {
  const toastOptions = {
    toastId: crc32(`${status}:${renderToStaticMarkup(<Router>{ notice.text }</Router>)}`).toString(),
  };

  if ('duration' in notice) {
    toastOptions.autoClose = notice.duration ? notice.duration * 1000 : false;
  }

  if ('containerId' in notice) {
    toastOptions.containerId = notice.containerId;
  }

  toastOptions.className = notice.className ?? '';
  if ('showDismiss' in notice && notice.showDismiss === false) {
    toastOptions.closeButton = false;
    toastOptions.draggable = false;
    toastOptions.className = classnames(toastOptions.className, 'no-dismiss');
  }

  if (toast.isActive(toastOptions.toastId)) {
    toast.update(toastOptions.toastId, {
      className: classnames(toastOptions.className, 'toast-updated'),
    });
    setTimeout(() => {
      toast.update(toastOptions.toastId, { className: toastOptions.className });
    }, 600); //timeout must match css animation time

    // return a dummy action with a `type` so middleware doesn't freak out
    return { type: 'NOTICE_TOAST_UPDATE', notice };
  }

  switch (status) {
    case NOTICE_ERROR:
      toast.error(text, toastOptions);
      break;
    case NOTICE_INFO:
      toast.info(text, toastOptions);
      break;
    case NOTICE_WARNING:
      toast.warn(text, toastOptions);
      break;
    case NOTICE_SUCCESS:
    default:
      toast.success(text, toastOptions);
      break;
  }
  return { type: NOTICE_TOAST, notice };
}

export function createNotice(status, text, options = {}) {
  const notice = buildNotice({ status, text, ...options });

  if (!notice.target) {
    // If no `target` is included, this returns a thunk which
    // checks the current state to see if a dialog is open. If
    // open it locks the notice to display in dialogs only.
    // To disable, send `target: 'none'`
    return (dispatch, getState) => {
      const openDialogs = getState().dialogs.length > 0;
      notice.target = openDialogs ? 'dialog' : 'page';
      if (notice.target === 'dialog') {
        return dispatch({ type: NOTICE_ADD, notice });
      }
      return sendToast(status, text, notice);
    };
  }
  if (notice.target === 'dialog') {
    return { type: NOTICE_ADD, notice };
  }
  return sendToast(status, text, notice);
}

export function setRoute(path) {
  return {
    type: ROUTE_SET,
    path: path,
  };
}

export const successNotice = createNotice.bind(null, NOTICE_SUCCESS);
export const errorNotice = createNotice.bind(null, NOTICE_ERROR);
export const infoNotice = createNotice.bind(null, NOTICE_INFO);
export const warningNotice = createNotice.bind(null, NOTICE_WARNING);
