import { defaultsDeep } from 'lodash';

export default class Gitlab {

  options = {};

  constructor(props) {
    const defaultOptions = {
      url: 'https://workbot.api.wheniwork-staging.com/gitlab',
      apiVersion: 'v4',
      isAdmin: 'false',
    };

    this.options = defaultsDeep({}, props, defaultOptions);
  }


  // istanbul ignore next
  send(url, opts = {}) {
    const options = {};

    if (!url) {
      throw new Error('Please include a `url` option for each request.');
    }
    if (url[0] !== '/') {
      throw new Error('Please prefix your `url` option with `/`');
    }
    delete options.url;

    return fetch(`${this.options.url}/api/${this.options.apiVersion}${url}`, options)
      .then(this.checkStatus)
      .then(this.parseResponse);
  }

  // istanbul ignore next
  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    return response.json().then(body => {
      const error = new Error(response.statusText);
      error.response = response;
      error.body = body;
      throw error;
    });
  }

  // istanbul ignore next
  parseResponse(response) {
    if (response.status === 204) {
      return response.text();
    }
    return response.json();
  }
}
