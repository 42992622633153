import { Component } from 'react';
import PropTypes from 'prop-types';

import { InputWrapper } from 'shared/components/form/inputs';
import { trackClick } from 'shared/util/tracking/mercury';

import { withFormsy } from 'formsy-react';
import { debounce } from 'lodash';
import { Input as BootstrapInput } from 'reactstrap';

export class GeoInput extends Component {

  state = {
    search: '',
    selected: false,
    searching: false,
    places: [],
    selectedPlace: '',
    showSuggestions: false,

    didBlur: false,
    didShowSuggestions: false,
    didHideSuggestions: false,
  };

  static propTypes = {
    setPlaceData: PropTypes.func.isRequired,

    // Form input props
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    useTitleAsValue: PropTypes.bool,

    // withFormsy props
    isPristine: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    showError: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    type: 'text',
    width: 12,
  };

  UNSAFE_componentWillMount() {
    this.autocomplete = new google.maps.places.AutocompleteService();
  }

  modifyValue = ({ target }) => {
    const value = target.value;

    this.setState({ search: value });
    this.props.setValue(undefined);

    if (value.length >= 3) {
      this.setState({ selected: false, searching: true }, this.beginSearch);
    } else {
      // If the field gets emptied out, turn off errors again
      // so they don't appear as the user types

      this.setState({
        didBlur: false,
        didShowSuggestions: false,
        didHideSuggestions: false,
      });
      this.setPlaces([]);
    }
  };

  beginSearch = debounce(() => {
    this.getGooglePredictions(this.state.search).then(placesData => {
      let places = [];
      if (placesData) {
        places = placesData.map(place => {
          const parts = place.description.split(', ');
          place.title = parts.shift();
          place.address = parts.join(', ');
          return place;
        });
      }

      this.setState({ searching: false });
      this.setPlaces(places);
    });
  }, 500);

  getGooglePredictions(query) {
    const opts = {
      input: query,
      types: ['geocode', 'establishment'],
    };

    return new Promise(resolve => {
      this.autocomplete.getPlacePredictions(opts, places => {
        resolve(places);
      });
    });
  }

  setPlace = place => {
    this.props.setValue(this.props.useTitleAsValue ? place.title : place.description);
    this.setState({
      selected: true,
      search: this.props.useTitleAsValue ? place.title : place.description,
      selectedPlace: this.props.useTitleAsValue ? place.title : place.description,
    });
    this.props.setPlaceData(place);
    this.setPlaces([]);
    trackClick(place.title);
  };

  setPlaces = places => {
    if (this.state.search.length < 3) {
      return;
    }

    const stateUpdate = { places };

    stateUpdate.showSuggestions = (places.length > 0 && !this.props.value);
    if (this.state.showSuggestions && !stateUpdate.showSuggestions) {
      // Suggestions are currently shown but about to be hidden
      stateUpdate.didHideSuggestions = true;
    } else if (!this.state.showSuggestions && stateUpdate.showSuggestions) {
      // Suggestions are currently hidden but about to be shown
      stateUpdate.didShowSuggestions = true;
    }

    this.setState(stateUpdate);
  };

  onBlur = () => {
    this.setState({ focus: false });
    // If the field has been blurred, show error messages
    this.setState({ didBlur: true });
    this.props.setValue(this.state.search);
  };
  onFocus = () => this.setState({ focus: true });

  renderInput(invalid) {
    const style = {};
    if (!this.props.value && !invalid) {
      style.color = '#999';
    }



    const { name, inputProps } = this.props;
    return (
      <BootstrapInput
        name={ name }
        type="text"
        invalid={ invalid }
        value={ this.state.search }
        onChange={ this.modifyValue }
        onBlur={ this.onBlur }
        onFocus={ this.onFocus }
        autoComplete="off"
        icon={ this.props.icon }
        style={ style }
        { ...inputProps }
      />
    );
  }

  renderSuggestions() {
    if (!this.state.showSuggestions) {
      return null;
    }

    return (
      <div className="geo-list">
        { this.state.places.map(place => (
          <a key={ place.place_id } onClick={ this.setPlace.bind(null, place) }>
            <div className="title">{ place.title }</div>
            <div className="address">{ place.address }</div>
          </a>
        ))
        }
      </div>
    );
  }

  render() {
    // The logic for this probably doesn't make sense at a glance, but I have
    // a truth table to justify it. Contact me for the full thing, but here's
    // the gist of it (true/false values for didBlur, didShow, and didHide,
    // and arrow to desired outcome):
    //
    // User types a value but hasn't done anything else:        F F F -> F
    // User types a value, clicks and holds on a suggestions:   T T F -> F
    // User types a value, clicks a suggestion completely:      T T T -> T
    // User types a value, then just clicks outside the field:  T T T -> T
    // User just blurs the field:                               T F F -> T
    //
    // - Ben Visness, 2017-10-09
    const canShowErrors = (this.state.didBlur && (this.state.didShowSuggestions === this.state.didHideSuggestions));

    const invalid = canShowErrors && ((this.props.showError || !this.props.isValid) &&
      (this.props.validatePristine || !this.props.isPristine) &&
      !(this.props.validateOnBlur && !this.state.didBlur));

    return (
      <InputWrapper
        classNames="geosuggest"
        onClickOutside={ () => {
          this.setPlaces([]);
        } }
        invalid={ invalid }
        { ...this.props }
        focus={ this.state?.focus }
      >
        { this.renderInput(invalid) }
        { this.renderSuggestions() }
      </InputWrapper>
    );
  }
}

export default withFormsy(GeoInput);
