import PropTypes from 'prop-types';

import ErrorBoundary from 'shared/components/ErrorBoundary';

import classnames from 'classnames';

import CityScape from 'accounts/assets/img/cityscape.svg';

export const Panel = props => {
  const { children, className, title } = props;
  return (
    <div
      className={ classnames('panel', className) }
      style={ props.style }
    >
      <ErrorBoundary>
        { title ? <PanelTitle children={ title } /> : null }
        { children }
      </ErrorBoundary>
    </div>
  );
};
Panel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};

export const PanelTitle = props => {
  const { children, className, size } = props;
  return (
    <div
      className={ classnames('panel-title', className, { [`title-${ size }`]: !!size }) }
      style={ props.style }
    >
      { children }
    </div>
  );
};
PanelTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
};

export const PanelBody = props => {
  const { children, className } = props;

  const computedClasses = {
    'with-title': !!props.title,
    'with-cityscape': !!props.cityscape,
  };

  return (
    <div className={ classnames('panel-body', className, computedClasses) } style={ props.style }>
      { props.title &&
        <div className="panel-title-row">
          <div className="col text-center">
            <h1>{ props.title }</h1>
          </div>
        </div>
      }
      <div
        className={ classnames('panel-body-content', className) }
      >
        { children }
      </div>
      { props.cityscape &&
        <div className="cityscape" style={ { backgroundImage: `url(${ CityScape })` } } />
      }
    </div>
  );
};
PanelBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  cityscape: PropTypes.any,
};
