import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAuthAccount, getAuthUser } from 'shared/auth';

import { get as _get } from 'lodash';
import { useLocation } from 'react-router-dom';

export default function GTMTracker() {
  window.dataLayer = window.dataLayer || [];

  const location = useLocation();
  const user = useSelector(getAuthUser);
  const account = useSelector(getAuthAccount);

  useEffect(() => {
    if (!user || !account) {
      return;
    }
    // no need to wait for GTM to load
    // GTM gracefully handles data being pushed to dataLayer before it's loaded.
    window.dataLayer.push({
      event: 'userAttributesSet',
      user: {
        id: user.id,
        title: user.roleName(),
      },
      account: {
        id: account.id,
        expectedEmployees: account.refEmployees,
        plan: {
          id: account.plan_id,
        },
      },
    });
  }, [user?.id, account?.id, location]);

  return null;
}

// GTM event name: 'sign_up', GTM Trigger: 'GA4 - Signup Completed - marketing site'
export function gaSignupSuccess(accountID, refEmployees) {
  try {
    // if gtm is being blocked, move on
    if (!_get(window, 'google_tag_manager.dataLayer.gtmLoad', false)) {
      return;
    } else {
      window.dataLayer && window.dataLayer.push({
        event: 'sign_up',
        method: 'WIW',
        expectedEmployees: refEmployees,
        account_id: accountID,
        eventCallback: () => {
          console.log(
            'Signup Successful',
            accountID,
            refEmployees,
          );
        },
      });
    }
  } catch (error) {
    console.log('GA Response Failed', error);
  }
}
