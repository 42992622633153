import User from 'data/user/model';
import { getAuthUser } from 'shared/auth';
import {
  CLEAR_LOGIN_DESTINATION,
  EXIT_APP,
  SET_LOGIN_DESTINATION,
} from 'store/actionTypes';
import { clearEntryInfoAction } from './entryInfo';

import Cookies from 'js-cookie';

export function setLoginDestination(path) {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.bootstrap.loginDestination) {
      dispatch({
        type: SET_LOGIN_DESTINATION,
        path: path,
      });
    }
  };
}

export function clearLoginDestination() {
  return {
    type: CLEAR_LOGIN_DESTINATION,
  };
}

/**
 * Tidies up any lingering session stuff, and directs the user out of the login frontend to wherever
 * they're going. It decides where to send them according to the following rules:
 *
 * 1. If a destination is explicitly provided, they will be taken there.
 * 2. Otherwise, if a `redirect` parameter was specified, they will be taken there.
 * 3. Otherwise, it will take them straight to the app.
 *
 * Most of the time you can just call exitApp() and it will do the right thing.
 *
 * @param {string|null} destination The URL (relative or absolute) to which the user should be sent.
 */
export function exitApp(destination = null, user = null) {
  return (dispatch, getState) => {
    // Must grab this before the entry info is cleared.
    const store = getState();
    const { bootstrap } = store;

    dispatch(clearEntryInfoAction());
    dispatch({ type: EXIT_APP });

    const selectedUser = user || getAuthUser(store);

    let fallbackUrl = null;
    if (selectedUser && selectedUser.role === User.Role.EMPLOYEE) {
      fallbackUrl = `${ CONFIG.FRONTEND_URL }/myschedule`;
    }

    // Redirect URL will always contain a full address including an HTTPS
    const redirectUrl = destination || (bootstrap.entry && bootstrap.entry.redirect) || fallbackUrl || CONFIG.FRONTEND_URL;

    Cookies.remove('W-Redirect', { domain: `.${ CONFIG.ROOT_DOMAIN }` });

    window.location = redirectUrl;
  };
}
