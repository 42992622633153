import isFullName from './isFullName';
import isPhone from './isPhone';
import isPhoneMarketingRules from './isPhoneMarketingRules';
import isPhoneOrEmail from './isPhoneOrEmail';
import isStringWithSpecial from './isStringWithSpecial';

import { Map } from 'immutable';

const validators = Map({
  isFullName,
  isPhone,
  isPhoneOrEmail,
  isStringWithSpecial,
  isPhoneMarketingRules,
});

export default validators;
