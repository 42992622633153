import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import SmallHeader from 'shared/components/SmallHeader';
import { trackClick } from 'shared/util/tracking/mercury';
import Button from 'wiw/ui/Button';

import 'shared/assets/styles/ErrorBoundary.scss';

/**
 * Component can be used as a wrapper, to catch child component errors,
 * or can be used as UI element when the parent catches errors instead
 */
export default class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.object,
    fallback: PropTypes.node,
  };

  /**
   * https://reactjs.org/docs/react-component.html#componentdidcatch
   *
   * @param error the raw exception
   * @param errorInfo an object with a "componentStack" property
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  reload = () => {
    window.location.assign(window.location.href.replace('#', ''));
  };

  render() {
    if (!this.props.error && !this.state.error) {
      return this.props.children
        ? (<Fragment>
          { this.props.children }
        </Fragment>)
        : null;
    }

    if (this.props.fallback) {
      return this.props.fallback;
    }

    return (
      <div>
        <div className="panel-header">
          <SmallHeader />
        </div>
        <div className="panel-body row error-boundary">
          <div className="col">
            <h1 className="error-title" id="error-title">Oops, something went wrong.</h1>
            <div className="row justify-content-md-center">
              <h6 className="error-text">Try reloading the page.</h6>
            </div>
            <div className="row justify-content-md-center">
              <Button
                className="mt-3 error-button"
                onClick={ event => { trackClick(event.target.innerText); this.reload(); } }
                size="lg">Refresh</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
