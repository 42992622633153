import mixpanel from 'mixpanel-browser';

mixpanel.init(CONFIG.MIXPANEL_TOKEN);

export const mixpanelTrack = (eventName, properties, options = {}) => {
  mixpanel.track(eventName, properties, options);
};

export const mixpanelIdentify = identifier => {
  mixpanel.identify(identifier);
};

export const mixpanelRegister = (superProperties = {}, persist = false) => {
  mixpanel.register(superProperties, { persistent: persist });
};

export default {
  mixpanelTrack,
  mixpanelIdentify,
  mixpanelRegister,
};
