import fetchUsers from 'data/user/actions/fetchUsers';
import User from 'data/user/model';
import { toEntityMap } from 'shared/util/toEntityMap';
import EntityState from 'store/EntityState';
import generateThunkReducers from 'store/util/generateThunkReducers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    receiveUsers: (state, action) => {
      return state.merge({
        loading: false,
        loaded: true,
        receivedAt: Date.now(),
        error: null,
        items: toEntityMap(action.payload, User),
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        return state.merge({
          items: toEntityMap(action.payload?.data?.users, User),
        });
      });
    generateThunkReducers(builder, 'user');
  },
});

export const { receiveUsers } = userSlice.actions;
export default userSlice;
