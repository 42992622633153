/* istanbul ignore file */

import MFADialogs from 'mfa/dialogs';
import RegisterDialogs from 'register/dialogs';
import SearchDialogs from 'search/dialogs';
// these are because istanbul has issues with this object of components they are in fact covered by snapshots however
/* istanbul ignore next */
const dialogs = {
  // Global, Reusable Dialogs
  ...MFADialogs,
  ...RegisterDialogs,
  ...SearchDialogs,
};

const transformer = (dialogs, transformers) => state => {
  return transformers.reduce((dialogs, transformer) => transformer(dialogs, state), dialogs);
};

export default transformer(dialogs, []);
