import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeNotice } from 'wiw/notices/actions';
import CloseToast from 'wiw/notices/components/CloseToast';
import Notice from 'wiw/notices/components/notice';

import cx from 'classnames';
import { debounce } from 'lodash';
import { Slide, ToastContainer } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';

import 'wiw/notices/assets/styles/BannerNotices.scss';
import 'wiw/notices/assets/styles/ToastNotices.scss';

type NoticeListProps = {
  className?: string,
  containerId?: string,
  defaultDuration?: number,
  dialog?: boolean,
  followWindow?: boolean,
  maxVisible?: number,
};

export function NoticeList({ defaultDuration = 3, maxVisible = 1, ...props }: NoticeListProps) {
  const noticeListRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const notices = useSelector<any, Array<any>>((state: any) => state.notices.items);
  const isDialogOpen = useSelector<any, boolean>(state => state.dialogs.length > 0);
  const [sticky, setSticky] = useState(false);

  function relocate() {
    if (!noticeListRef.current) {
      return;
    }

    setSticky(noticeListRef.current.getBoundingClientRect().top < 0);
  }

  const debouncedRelocate = useMemo(() => debounce(relocate, 20), []);

  useEffect(() => {
    debouncedRelocate();
    if (props.followWindow) {
      window.addEventListener('scroll', debouncedRelocate);
    }

    return () => {
      debouncedRelocate.cancel();
      window.removeEventListener('scroll', debouncedRelocate);
    };
  }, [props.followWindow, debouncedRelocate]);

  const wrapperClasses = cx('notice-list', {
    'dialog-layout': props.dialog,
    'page-layout': !props.dialog,
  });

  if (props.dialog) {
    const allowed = notices.filter(notice => {
      if (props.dialog) {
        return !notice.target || notice.target !== 'page';
      }

      return (!isDialogOpen && !notice.target) || (notice.target && notice.target !== 'dialog');
    });

    const showCount = maxVisible === 0 ? notices.length : Math.min(maxVisible, notices.length);
    const items = allowed.slice(0, showCount).map(notice => {
      return (
        <Notice
          id={ notice.noticeId }
          key={ `notice-${notice.noticeId}` }
          status={ notice.status }
          duration={ notice.duration ?? defaultDuration }
          showDismiss={ notice.showDismiss }
          onDismiss={ () => dispatch(removeNotice(notice.noticeId)) }
          content={ notice.text }
          animation={ notice.animation }
        />
      );
    });

    return <div ref={ noticeListRef } className={ wrapperClasses }>
      <div className='scroll-wrapper'>
        <TransitionGroup component={ null }>{ items }</TransitionGroup>
      </div>
    </div>;
  }

  return <div ref={ noticeListRef } className={ wrapperClasses }>
    <ToastContainer
      className={ cx(props.className, { 'toast-sticky': sticky }) }
      containerId={ props.containerId }
      transition={ Slide }
      closeOnClick={ false }
      // the closeToast prop gets overriden with the actual function when rendered, this is to appease TypeScript
      closeButton={ <CloseToast closeToast={ () => {} } /> }
      enableMultiContainer
      hideProgressBar
    />
  </div>;
}
