import 'shared/assets/styles/accountLogo.scss';
import defaultLogo from 'shared/assets/account-icon-default.svg';

type AccountLogoProps = {
  account?: {
    logo: string
  }
  logo?: string
}
export default function AccountLogo({ account, logo }:AccountLogoProps) {
  return (
    <img
      className="account-logo"
      src={ account?.logo || logo || defaultLogo }
      role="presentation"
      alt=""
    />
  );
}
