import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bootstrap } from 'data/bootstrap/actions/bootstrap';
import { AUTH_MODE, getToken } from 'shared/auth';
import getAuthMode from 'shared/auth/selectors/getAuthMode';
import isLoggedIn from 'shared/auth/selectors/isLoggedIn';
import { setLoginDestination } from 'store/actions/app';
import isBootstrapped from 'store/selectors/isBootstrapped';

import { useHistory } from 'react-router-dom';

type AuthRoutineOptions = {
  needsAuth: boolean,
  redirectBack?: string,
  authRedirect: boolean,
};

export function useAuthRoutine({ authRedirect, needsAuth = true, redirectBack }: AuthRoutineOptions) {
  const dispatch = useDispatch();
  const bootstrapped = useSelector(isBootstrapped);
  const loggedIn = useSelector(isLoggedIn);
  const authMode = useSelector(getAuthMode);
  const history = useHistory();

  useEffect(() => {
    if (!bootstrapped) {
      dispatch(bootstrap(getToken(), history));
    }

    if (needsAuth) {
      if (bootstrapped && !loggedIn) {
        if (redirectBack) {
          dispatch(setLoginDestination(window.location.pathname));
        }

        if (history.location.pathname !== '/' && authMode !== AUTH_MODE.MFA) {
          return history.push('/');
        }
      }
    } else {
      if (loggedIn && authRedirect) {
        if (history.location.pathname !== '/accounts') {
          return history.push('/accounts');
        }
      }
    }
  }, [authMode, authRedirect, bootstrapped, dispatch, history, loggedIn, needsAuth, redirectBack]);
}
