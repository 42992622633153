import validators from './validators';

import FormsyForm, { addValidationRule } from 'formsy-react';


validators.map((validator, name) => {
  addValidationRule(name, validator);
});

export default FormsyForm;

export * from '../form/inputs';
