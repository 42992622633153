import { Component } from 'react';
import PropTypes from 'prop-types';

import Async from 'react-select/async';

class BranchPicker extends Component {

  static propTypes = {
    float: PropTypes.oneOf(['left', 'right']).isRequired,
    label: PropTypes.string.isRequired,
    currentBranch: PropTypes.string.isRequired,
    loadBranches: PropTypes.func.isRequired,
    changeBranch: PropTypes.func.isRequired,
    branchLink: PropTypes.element,
  };

  static defaultProps = {
    float: 'left',
    currentBranch: 'master',
    branchLink: null,
  };

  render() {
    const { label, currentBranch, loadBranches, changeBranch, branchLink } = this.props;
    return (
      <form className="col">
        <div className="form-group">
          <label htmlFor="branch">{ label }</label>
          <Async
            defaultOptions
            cacheOptions
            name="branch"
            clearable={ false }
            value={ { label: currentBranch, value: currentBranch } }
            loadOptions={ loadBranches }
            onChange={ changeBranch }
          />
          { branchLink }
        </div>
      </form>
    );
  }
}

export default BranchPicker;
