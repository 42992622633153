import Gitlab from './gitlab';

const service = new Gitlab({
  url: 'https://workbot.api.wheniwork-staging.com/gitlab',
  apiVersion: 'v4',
  admin: false,
});
export default service;

export const wiwjsProjectID = 3;

export const getBranches = (projectID = wiwjsProjectID, query, replaceSlashes = false) => {
  return service.send(`/projects/${projectID}/repository/branches?search=${query}`)
    .then(branches => {
      if (!branches.filter(branch => branch.name === 'master').length) {
        branches.push({ name: 'master' });
      }
      return branches.map(branch => ({
        label: branch.name,
        value: replaceSlashes ? branch.name.replace('/', '-') : branch.name,
      }));
    });
};


