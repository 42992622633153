import { createContext } from 'react';

export const DialogContext = createContext({
  closeDialog: null,
  setOnDismiss: null,
  isInDialog: false,
});

export function withDialogContext(Component) {
  return function DialogWithCtx(props) {
    return (
      <DialogContext.Consumer>
        { value => (
          <Component
            closeDialog={ value.closeDialog }
            setOnDismiss={ value.setOnDismiss }
            isInDialog={ value.isInDialog }
            dialogErr={ value.err }
            dialogErrInfo={ value.errInfo }
            { ...props } />
        ) }
      </DialogContext.Consumer>
    );
  };
}
