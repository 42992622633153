import authSlice from 'data/auth';
import getStateUsers from 'data/user/selectors/getStateUsers';

export default function setActiveUser(userId) {
  return (dispatch, getStore) => {
    userId = parseInt(userId, 10);
    const store = getStore();
    const users = getStateUsers(store).items;
    // ensure the token user is available for the current session
    if (users.has(userId)) {
      dispatch(authSlice.actions.setAuthUser(userId));
    }
  };
}
