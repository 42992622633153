import loaderBackground from 'shared/assets/loader-background.svg';
import loaderSegment from 'shared/assets/loader-segment.svg';

const LoadingApp = () => (
  <div className="loading-app">
    <img className="loader-background" src={ loaderBackground } />
    <img className="loader-segment" src={ loaderSegment } />
  </div>
);

export default LoadingApp;
