import {
  DIALOG_OPEN,
  DIALOG_UPDATE,
  DIALOG_CLOSE,
} from 'store/actionTypes';

const focusStack = []; // TODO: move to DialogContainer

export function openDialog(dialog, payload = {}, options = {}) {
  const stackFrame = document.activeElement ? document.activeElement : null;
  focusStack.push(stackFrame);

  // ignore payload if it is an event to avoid react crying about synthetic events
  // payload could be null, string, object, etc.
  if (payload !== null && typeof payload === 'object' &&
    Object.prototype.hasOwnProperty.call(payload, 'currentTarget')) {
    payload = {};
  }
  return {
    type: DIALOG_OPEN,
    dialog,
    payload,
    options,
  };
}

export function updateDialog(dialog, payload = {}, options = {}) {
  return {
    type: DIALOG_UPDATE,
    dialog,
    payload,
    options,
  };
}

export function closeDialog(dialog) {
  const element = focusStack.pop();

  if (element) {
    try {
      element.focus();
    } catch (error) {
      // suppress errors, element may have been removed from DOM
    }
  }

  return {
    type: DIALOG_CLOSE,
    dialog,
  };
}
