import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dialog, Header, Body, Footer } from 'wiw/dialogs';
import Button from 'wiw/ui/Button';

export class ChangeMFAMethodDialog extends Component {

  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  render() {
    return (
      <Dialog width={ 500 } className="approve-time-dialog">
        <Header onClose={ this.props.closeDialog }>
          Change Two-step verification method
        </Header>
        <Body>
          <p>Are you sure you want to change your verification method?</p>
        </Body>
        <Footer>
          <div className="row justify-content-end mr-1">
            <div className="col-auto mr-2">
              <Button block={ false } onClick={ this.props.closeDialog } color="secondary">Cancel</Button>
            </div>
            <div className="col-auto">
              <Link className="btn btn-primary" to="setup">Continue</Link>
            </div>
          </div>
        </Footer>
      </Dialog>
    );
  }
}

export default connect(
  state => ({}),
  {},
)(ChangeMFAMethodDialog);
