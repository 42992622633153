import React from 'react';

import resolveLoginDestination from 'data/bootstrap/actions/resolveLoginDestination';
import personSlice from 'data/person';
import Login from 'shared/api/login';
import { setToken } from 'shared/auth';
import { errorNotice } from 'wiw/notices/actions';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import { history } from 'routes';

function loginErrorMessage(error, attemptNumber) {
  const code = error.code;
  const errorMessage = error.message;

  if (code === 'INCORRECT_EMAIL_OR_PASSWORD') {
    return {
      message: 'Incorrect username and/or password. Please try again.',
      duration: 3,
    };
  }

  if (code === 'SSO_ONLY' && !errorMessage) {
    return {
      message: 'Your workplace uses a third-party to log in. Select the Third Party Connect option to continue.',
      duration: 0,
    };
  }

  if (code === 'SSO_ONLY' && errorMessage) {
    return {
      message: 'It appears you\'ve used your Google or Apple account to create your account. Select the correct option to continue.',
      duration: 0,
    };
  }

  if (code === 'PASSWORD_RESET_REQUIRED' && errorMessage) {
    return {
      message: <span>Your login has been temporarily deactivated. Please complete the <Link to="/forgot">Forgot Password</Link> steps to restore access.</span>,
      duration: 0,
    };
  }

  if (code === 'TOO_MANY_FAILED_ATTEMPTS') {
    return {
      message: errorMessage,
      duration: 3,
    };
  }

  if (code === 'MFA_CODE_REQUIRED') {
    return {
      message: 'Please verify your login with your authentication code',
      duration: 3,
    };
  }

  return {
    message: 'There was an error logging in. Please try again later.',
    duration: 3,
  };
}

export default createAsyncThunk(
  'auth/login',
  async ({ data: { email, password }, attemptNumber, redirectMfa = true }, { rejectWithValue, dispatch }) => {
    try {
      const response = await Login.post('/login', { email, password });

      setToken(response.data.token);
      dispatch(resolveLoginDestination());
      dispatch(personSlice.actions.receivePerson(response.data.person));
      return response.data;
    } catch (error) {
      if (error.code === 'MFA_CODE_REQUIRED') {
        setToken(error.response.data.data.mfa_token);
        if (redirectMfa) {
          return history.push('/mfa/confirm');
        }
        // Don't want to show error toast for mfa login required responses
        return rejectWithValue(error);
      }
      const errorToast = loginErrorMessage(error, attemptNumber);
      dispatch(errorNotice(errorToast.message, { duration: errorToast.duration }));
      return rejectWithValue(error);
    }
  },
);
