import Account from 'data/account/model';
import receiveUsers from 'data/user/actions/receiveUsers';
import User from 'data/user/model';
import { getUserId } from 'shared/auth';
import Identify from 'shared/vendor/identify';
import { ACCOUNTS_LIST } from 'store/actionTypes';

export function receiveAccountsRequest(params = {}) {
  return {
    type: ACCOUNTS_LIST.REQUEST,
    params,
  };
}

export function receiveAccountsFailed(error) {
  return {
    type: ACCOUNTS_LIST.FAILURE,
    payload: error,
  };
}

export function receiveAccountsSuccess(payload = {}, finalFetch = true) {
  return {
    type: ACCOUNTS_LIST.SUCCESS,
    payload: payload,
    loading: !finalFetch,
  };
}

// finalFetch allows us to manually control the loading state of the accounts store depending on if we've got a bunch of
// requests in flight. The fetchAccountsAndRedirect action can fire up to 6 times in sequence trying to determine if
// accounts exist for the logged-in user for... reasons and without manually controlling the loading bool we can't show
// a proper loading state on the components that are waiting for the train of requests to resolve.
export default function fetchAccounts(finalFetch = true) {
  return dispatch => {
    dispatch(receiveAccountsRequest());
    return Account.list()
      .then(response => {
        const loadedAccounts = response.accounts?.length > 0;
        // if we've got accounts, we don't care about subsequent fetches in flight and can just set the store to loaded
        finalFetch = loadedAccounts ? loadedAccounts : finalFetch;
        dispatch(receiveUsers(response.users));
        dispatch(receiveAccountsSuccess(response.accounts, finalFetch));

        // Try to identify via W-UserId cookie, otherwise just use the first ones in the response
        const userId = parseInt(getUserId());
        const responseUser = response.users?.find(user => user.id === userId) || response.users[0];
        const accountId = responseUser?.account_id;
        const responseAccount = response.accounts?.find(account => account.id === accountId) || response.accounts[0];
        const user = responseUser ? new User(responseUser) : null;
        const account = loadedAccounts && responseAccount ? new Account(responseAccount) : null;
        const login = response.login || null;
        
        if (user && account) {
          Identify.identifyThirdParty(login, user, account, dispatch);
        } else {
          Identify.identifyThirdPartyNoUserOrAccount(login, dispatch);
        }
        return response;
      })
      .catch(error => dispatch(receiveAccountsFailed(error)));
  };
}
