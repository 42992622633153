import FreeTrial from 'reactivate/models/FreeTrial';
import { CREATE_SUBSCRIPTION, FETCH_TRIAL_STATUS } from 'store/actionTypes';

import { Map } from 'immutable';

export const initialState = Map({
  status: new FreeTrial(),
});

export default function (state = initialState, action) {

  switch (action.type) {
    case FETCH_TRIAL_STATUS.SUCCESS:
      return state.set('status', new FreeTrial(action.payload));
    case CREATE_SUBSCRIPTION.SUCCESS:
      return state.set('status', new FreeTrial(action.payload));
    default:
      return state;
  }
}
