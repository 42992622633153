import { Component } from 'react';
import PropTypes from 'prop-types';

import bodymovin from 'bodymovin';

class Bodymovin extends Component {

  static propTypes = {
    animationData: PropTypes.object.isRequired,
    options: PropTypes.object,
    className: PropTypes.string,
  };

  componentDidMount() {
    const options = {
      container: this.container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: this.props.animationData,
      ...this.props.options,
    };

    this.animation = bodymovin.loadAnimation(options);
  }

  componentWillUnmount() {
    this.animation.destroy();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const storeContainer = el => {
      this.container = el;
    };

    return <div ref={ storeContainer } className={ this.props.className } />;
  }

}

export default Bodymovin;
