import Immutable from 'immutable';
import request from 'shared/request';

class Person extends Immutable.Record({
  id: null,
  type: 'person',
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  updatedAt: null,
  createdAt: null,
  mfa: {
    enabled: false,
    required: false,
    type: null,
  },
}) {

  static fullName({ firstName, lastName }) {
    let tokens = [];
    if (firstName) {
      tokens.push(firstName);
    }
    if (lastName) {
      tokens.push(lastName);
    }

    return tokens.join(' ');
  }

  static login({ email, password }) {
    return request().post('/login', {
      email,
      password,
    });
  }

  static create({ firstName, lastName, email, phone, password }) {
    return request().post('/people', {
      firstName,
      lastName,
      email,
      phone,
      password,
    });
  }

  static createThroughMonolith({ firstName, lastName, email, phone, password, accountId }) {
    return request('api').post('/login/register', {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      password: password,
      agree: true,
      account_id: accountId,
    });
  }

  static forgotPassword(email, messageType) {
    return request().post('/forgot', {
      Email: email,
      type: messageType, // different forgot email messages
    });
  }

  static resetPassword({ token, password }) {
    return request().post('/reset', {
      token,
      password,
    });
  }

  static checkResetToken({ token }) {
    return request().post('/reset/check', {
      token,
    });
  }
}

export default Person;
