import { clearLoginDestination } from 'store/actions/app';

import { history } from 'routes';

export default () => (dispatch, getState) => {
  const store = getState();

  if (store.bootstrap.loginDestination) {
    history.push(store.bootstrap.loginDestination);
    dispatch(clearLoginDestination());
  }
};
