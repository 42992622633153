import Environment from 'shared/util/environment';

import * as Sentry from '@sentry/react';

export function initSentry() {
  Sentry.init({
    release: CONFIG.DEPLOY.TAG || CONFIG.DEPLOY.SHA,
    environment: Environment.get(),
    dsn: 'https://a0aa8436e080c90738c9a7cd9174a840@o4506389234712576.ingest.sentry.io/4506547986563072',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        mask: ['.sentry-mask', '[data-sentry-mask]', '[data-private]'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.001,
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 50% when sampling sessions where errors occur.
  });
}
