import { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => (
  <div id='wheniwork-is-awesome'>
    <div id='content' className='content'>
      <div className='content--inside'>
        { children }
      </div>
    </div>
  </div>
);

export default Layout;
