import { ReactElement, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';

import isLoggedIn from 'shared/auth/selectors/isLoggedIn';
import Layout from 'shared/layouts/ReactivateLayout';
import LoadingApp from 'wiw/ui/loading/LoadingApp';

import { initialize } from 'launchdarkly-js-client-sdk';
import { useHistory } from 'react-router-dom';

type ReactivateLayout14Props = {
  location: ReactElement,
  rest: any,
  component: any
  pageName: string
  computedMatch: {
    path: string
  }
}

export default function ReactivateLayout14({ component: Component, location, pageName, computedMatch, ...rest }: ReactivateLayout14Props) {

  const history = useHistory();

  // convert route to a unique class name i.e /accounts/:id/something -> accounts-something dropping any params
  const routeName = computedMatch.path.split('/').filter(it => it.length && !it.startsWith(':')).join('-');

  useEffect(() => {
    const clientId = CONFIG.LAUNCH_DARKLY_CLIENT_ID;
    const ldclient = initialize(clientId, { 'key': 'login' });
    ldclient.on('ready', () => {
      if (!ldclient.variation('wiwjs-6048-14-trial-reactivation')) {
        history.replace('/login');
      }
    });
  }, []);

  const loggedIn = useSelector(isLoggedIn);

  useEffect(() => {
    if (loggedIn) {
      return history.push('/accounts');
    }
  }, [loggedIn]);

  return (
    <Layout
      title="Reactivate your free trial for another 14 days!"
      copy="Build the work schedule faster and share it instantly with your team.
      Then, try some of the other features you might have missed before."
      pageName={ pageName }
      routeName={ routeName }
    >
      <Suspense fallback={
        <div className="reactivate-loading">
          <LoadingApp />
        </div>
      }>
        <Component { ...rest } history={ history } location={ location } />
      </Suspense>
    </Layout>
  );
}
