import { identifyMercury } from 'shared/util/tracking/mercury';
import LaunchDarkly from 'shared/vendor/launchDarkly';
import { mixpanelIdentify } from 'shared/vendor/mixpanel';

export default class Identify {
  static identifyThirdParty(login, user, account, dispatch) {
    this.identifyLaunchDarkly(user, account, dispatch);
    this.identifyMixpanel(user);
    identifyMercury(user, account, login);
  }

  static identifyThirdPartyNoUserOrAccount(login, dispatch) {
    this.identifyLaunchDarkly(null, null, dispatch);
    identifyMercury(null, null, login);
  }

  static identifyLaunchDarkly(user, account, dispatch) {
    try {
      LaunchDarkly.init(user, account, dispatch)
        .then(() => {
          // could handle any further inits or identifies that depend on LD evaluation here, we don't have any though
        });
    } catch (e) {
      console.warn('Launch Darkly identification issue: ', e);
    }
  }

  static identifyMixpanel(user) {
    try {
      mixpanelIdentify(user.loginId);
    } catch (e) {
      console.warn('Mixpanel identification issue: ', e);
    }
  }
}
