import Cookies from 'js-cookie';
import { camelCase, trim } from 'lodash';
import { parse as parseQueryString } from 'query-string';
import { v4 as uuidv4 } from 'uuid';

export const MERCURY_ORIGIN = 'loginFrontendReact';
export const MERCURY_HOSTNAME = window.location.hostname !== '' ? window.location.hostname : null;
export const MERCURY_HASH = window.location.hash !== '' ? window.location.hash : null;
export const MERCURY_PATH = window.location.pathname === '' ? null : window.location.pathname;
export const MERCURY_REFERRER = document.referrer !== '' ? document.referrer : null ;
export const MERCURY_TIMING = JSON.stringify(window.performance.getEntriesByType('navigation')[0]) ?? null ;
export const MERCURY_SESSIONFLOWID = null ;

export const WEB_PAGEVIEW = 'web::pageView';
export const ROLLOUT_ASSIGNMENTS = 'rollout::assignments';
export const EXPERIMENT_ASSIGNMENTS = 'experiment::assignments';

export function getMercuryEnv() {
  switch (CONFIG.ENV) {
    case 'staging':
      return 'staging';
    case 'production':
      return 'production';
    default:
      return 'development';
  }
}

export const track = (eventName, fields = {}, data = {}) => {
  if (typeof window.mercury !== 'undefined') {
    data = addKeyIfNeeded(data);
    return window.mercury.track(eventName, fields, data);
  }
  return new Promise().resolve();
};

export const trackExperiment = (experimentId, variation, data = {}) => {
  if (typeof window.mercury !== 'undefined') {
    data = addKeyIfNeeded(data);
    return window.mercury.track(
      EXPERIMENT_ASSIGNMENTS,
      {
        experimentId: experimentId,
        variation: JSON.stringify(variation),
      },
      data
    );
  }
};

export const trackClick = (
  target = '',
  fields = {},
  data = {},
  eventName = 'web::onInteraction',
  pageSelector = '.wiw-container',
) => {
  if (typeof window.mercury !== 'undefined') {
    const pageContainer = document.querySelector(pageSelector);
    const userRole = fields.userRole ? fields.userRole : null;
    fields.target = target !== '' ? camelCase(trim(target)) : null;
    fields.appComponent = pageContainer?.dataset.view ?? null;
    fields.userRole = userRole;
    data = addKeyIfNeeded(data);

    return window.mercury.interaction(fields, data, eventName);
  }
  return new Promise().resolve();
};

export const trackPage = (eventName = 'web::pageView', fields = {}, data = {}) => {
  if (typeof window.mercury !== 'undefined') {
    data = addKeyIfNeeded(data);
    window.mercury.page(fields, data, eventName);
  }
};

export const trackRollout = (jiraIssue, ldFlag, variation, data = data, eventName = ROLLOUT_ASSIGNMENTS) => {
  if (typeof window.mercury !== 'undefined') {
    data = addKeyIfNeeded(data);
    return window.mercury.track(eventName, {
      rolloutName: ldFlag,
      rolloutId: jiraIssue,
      variation: JSON.stringify(variation),
    }, data);
  }
  return new Promise().resolve();
};

export const formatQueryString = search => {
  return search ? JSON.stringify(parseQueryString(search)) : null;
};

export const identifyMercury = (user, account, login) => {
  if (typeof window.mercury !== 'undefined') {
    if (login) {
      // mercury.identify turns ids into strings. mercury._buildKey looks for the first truthy value
      // so if these values do not exist, we need to set them to an empty string so they are falsy
      const accountId = account?.id || '';
      const userId = user?.id || '';
      const loginId = login?.id || '';
      window.mercury.identify(accountId, userId, loginId, MERCURY_ORIGIN, getMercuryEnv());
    }
  }
};

const addKeyIfNeeded = data => {
  const { accountId, personId } = window.mercury.identity;
  if (!accountId && !personId && !data.key) {
    return { ...data, key: userAttributionId() };
  }
  return data;
};

/**
 * if the user isn't logged in, we identify with a cookie
 * this matches the marketing site
 */
const userAttributionId = () => {
  let userAttributionId = Cookies.get('user_attribution_id');
  if (!userAttributionId) {
    userAttributionId = uuidv4();
    Cookies.set('user_attribution_id', userAttributionId, {
      domain: `.${CONFIG.ROOT_DOMAIN}`,
      secure: true,
      sameSite: 'Lax',
      expires: 1825, // five years
    });
  }

  return userAttributionId;
};

export default {
  track,
  trackClick,
  trackPage,
};
