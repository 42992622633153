import { ReactNode } from 'react';

import { NoticeList } from 'wiw/notices/components/list';

import 'reactivate/assets/css/reactivate.scss';
import Devices from 'reactivate/assets/img/devices-graphic.webp';
import Headshot from 'reactivate/assets/img/Mike-K.webp';
import Logo from 'shared/assets/wiw-logo.svg';

type LayoutProps = {
  title: string,
  copy: string,
  children?: ReactNode,
  pageName: string
  routeName: string
}


const ReactivateLayout = ({ children, title, copy, pageName, routeName }: LayoutProps) => {


  return (
    <div className="reactivate">
      <NoticeList maxVisible={ 5 } followWindow />
      <div className={ `wiw-container ${routeName}` } data-view={ pageName }>
        <div className="row align-items-center react-container">
          <div className="sidebar col">
            <div className="row">
              <div className="col login-wrapper">
                { children }
              </div>

              <div className="row">
                <div className="col">
                  <div className="testimonial">
                    <img className="mx-auto d-block" src={ Headshot } alt="Headshot" />
                    <p>
                      “I've been searching the internet for years for a good scheduling program and I've found nothing even close to what you have with When I Work.”
                    </p>
                    <div className="attribution">
                      Mike Koelzer <br />
                      <i>Kay Pharmacy</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col main-content">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="header col">
                    <img src={ Logo } alt="When I Work Logo" />
                    <h1>{ title }</h1>
                  </div>
                </div>
              </div>
              <div className="col">
                <p>{ copy }</p>
              </div>
              <div className="col-12 main-devices">
                <div className="row">
                  <img id="devicesImg" src={ Devices } alt="Devices" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-none device-img-mobile">
            <div className="row">
              <img className="img-fluid" id="devicesImg" src={ Devices } alt="Devices" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ReactivateLayout;
