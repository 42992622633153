import Account from 'data/account/model';
import setAuthAccount from 'data/auth/actions/setAuthAccount';
import User from 'data/user/model';
import { AUTH_MODE, getAuthUser } from 'shared/auth';

export default function autoSelectAccount() {
  return (dispatch, getStore) => {
    const store = getStore();
    const user = getAuthUser(store) || new User();

    if (store.auth.mode !== AUTH_MODE.SSO && user.id) {
      const accountId = store.data.accounts.items.get(user.accountId, new Account()).id;
      if (accountId) {
        return dispatch(setAuthAccount(accountId));
      }
    }
  };
}
