import { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { bootstrap } from 'data/bootstrap/actions/bootstrap';
import getBootstrap from 'data/bootstrap/selectors/getBootstrap';
import { AUTH_MODE, getToken } from 'shared/auth';
import getAuthMode from 'shared/auth/selectors/getAuthMode';
import isLoggedIn from 'shared/auth/selectors/isLoggedIn';
import BrowserGate from 'shared/components/BrowserGate';
import PageContainer from 'shared/components/PageContainer';
import SmallHeader from 'shared/components/SmallHeader';
import DevPanel from 'shared/dev/containers/DevPanel';
import { setLoginDestination } from 'store/actions/app';
import isBootstrapped from 'store/selectors/isBootstrapped';
import { NoticeList } from 'wiw/notices/components/list';
import { Panel } from 'wiw/ui';
import LoadingApp from 'wiw/ui/loading/LoadingApp';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';

export default function MainLayout({ auth = true, authRedirect = true, ...props }) {

  MainLayout.propTypes = {
    back: PropTypes.bool,
    location: PropTypes.object,
    pageName: PropTypes.string,
    logout: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.object]),
    redirectBack: PropTypes.bool,
    auth: PropTypes.bool,
    authRedirect: PropTypes.bool,
    computedMatch: PropTypes.object,
    recaptchaEnabled: PropTypes.bool,
  };

  const token = getToken();

  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector(isLoggedIn);
  const bootstrapState = useSelector(getBootstrap);
  const bootstrapped = useSelector(isBootstrapped);
  const authMode = useSelector(getAuthMode);

  useEffect(() => {
    if (!bootstrapped) {
      dispatch(bootstrap(token, history));
    }

    if (auth === true) {
      return authRoutine();
    }
    return unAuthedRoutine();

  }, [bootstrapState.complete, bootstrapState.loading, loggedIn]);

  const authRoutine = () => {
    if (bootstrapped && !loggedIn) {
      if (props.redirectBack) {
        dispatch(setLoginDestination(window.location.pathname));
      }

      if (history.location.pathname !== '/' && authMode !== AUTH_MODE.MFA) {
        return history.push('/');
      }

    }
  };

  const unAuthedRoutine = () => {
    if (loggedIn && (authRedirect === true)) {
      if (history.location.pathname !== '/accounts') {
        return history.push('/accounts');
      }
    }
  };

  const { component: Component, location, back, logout, recaptchaEnabled, ...rest } = props;

  if (!bootstrapped) {
    return <LoadingApp />;
  }

  // convert route to a unique class name i.e /accounts/:id/something -> accounts-something dropping any params
  const routeName = props.computedMatch.path.split('/').filter(it => it.length && !it.startsWith(':')).join('-');

  return (
    <BrowserGate>
      <div id="wheniwork-is-awesome">
        <div id="content" className="content">
          <div className="content--inside">
            <NoticeList maxVisible={ 5 } followWindow />
            <PageContainer className={ routeName === '' ? 'login' : routeName } pageName={ props.pageName }>
              <Panel>
                <SmallHeader back={ back } logout={ logout } />
                <Suspense fallback={
                  <div className="panel-loading">
                    <LoadingApp />
                  </div>
                }>
                  { recaptchaEnabled &&
                    <GoogleReCaptchaProvider reCaptchaKey={ CONFIG.RECAPTCHA_SITE_KEY } useEnterprise>
                      <Component { ...rest } history={ history } location={ location } />
                    </GoogleReCaptchaProvider> }
                  { !recaptchaEnabled &&
                    <Component { ...rest } history={ history } location={ location } />}
                </Suspense>
              </Panel>
            </PageContainer>
            <DevPanel />
          </div>
        </div>
      </div>
    </BrowserGate>
  );
}


