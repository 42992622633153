import { getUserId } from 'shared/auth';

import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getStateAuth = state => state.auth;
export const getStateUsers = state => get(state, 'data.users.items', []);
export const getStateAccounts = state => get(state, 'data.accounts.items', []);
const getStatePeople = state => get(state, 'data.person.items', []);

const filterStateUsers = users => {
  const id = getUserId();
  return users.get(parseInt(id, 10));
};

const filterStateAccounts = (accounts, id) => {
  return accounts.get(id);
};

export const getAuthUser = createSelector(
  getStateUsers,
  filterStateUsers,
);

export const getAuthAccount = createSelector(
  [getStateAccounts, state => state.auth.accountId],
  (accounts, id) => filterStateAccounts(accounts, id),
);

export const getAuthPerson = createSelector(
  getStatePeople,
  people => people.first(),
);

export const getAuthData = createSelector(
  [getStateUsers, getAuthAccount, getAuthPerson, getStateAuth],
  (users, account, person, auth) => {
    const user = filterStateUsers(users);
    if (!user) {
      return {
        loaded: auth.loaded,
      };
    }
    return {
      user,
      account,
      person,
      loaded: auth.loaded,
    };
  },
);

export const authorized = createSelector(
  state => state.auth,
  auth => auth && auth.loggedIn,
);
