export class ErrorResponse extends Error {
  response = null;
  body = null;

  constructor(response, body) {
    super(response.statusText);
    this.name = 'ErrorResponse';
    this.response = response;
    this.code = body?.code;
    this.body = body;
  }
}

export class ErrorResponseGoSingle extends ErrorResponse {
  constructor(response, body) {
    super(response, body);
    this.name = 'ErrorResponseGoSingle';
    this.code = body?.code;
    this.message = body.message;
  }
}

/**
 * Some endpoints (usually but not always in the monolith) return a JSON body with a single "error" string property
 *
 * Access with: errorResponseOne.body.error
 */
export class ErrorResponseOne extends ErrorResponse {
  constructor(response, body) {
    super(response, body);
    this.name = 'ErrorResponseOne';
    this.code = body?.code;
    this.message = body.error;
  }
}

/**
 * Some endpoints (particularly Golang, some monolith) return a JSON body with an "errors" property containing an
 * array of { code: "string", message: "string" } shaped objects
 *
 * Access with: errorResponseMany.body.errors
 */
export class ErrorResponseMany extends ErrorResponse {
  constructor(response, body) {
    super(response, body);
    this.name = 'ErrorResponseMany';
    this.message = body.errors.map(err => err.message ? err.message : err.toString()).join('\n');
  }
}
