import Monolith from 'shared/api/monolith';

import { createAsyncThunk } from '@reduxjs/toolkit';

export default createAsyncThunk(
  'users/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      return await Monolith.get('/users');
    } catch (err) {
      return rejectWithValue(err);
    }
  });
