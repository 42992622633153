import { combineReducers } from 'redux';

import accounts from 'data/account/reducer';
import auth from 'data/auth';
import bootstrap from 'data/bootstrap';
import industries from 'data/industries';
import person from 'data/person';
import timezones from 'data/timezones/reducer';
import users from 'data/user';
import trial from 'reactivate/reducer';
import search from 'search/reducer';
import dialogs from 'wiw/dialogs/reducer';
import notices from 'wiw/notices/reducer';

import { reducer as launchDarklyReducer } from 'ld-redux';

export default combineReducers({
  auth: auth.reducer,
  bootstrap: bootstrap.reducer,
  data: combineReducers({
    accounts,
    timezones,
    industries: industries.reducer,
    users: users.reducer,
    person: person.reducer,
  }),
  dialogs,
  notices,
  search,
  trial,
  LD: launchDarklyReducer,
});
