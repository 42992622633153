import { useEffect } from 'react';

export function useWhiteBg(preferHas = false) {
  useEffect(() => {
    let supportsHas = false;
    if (preferHas) {
      supportsHas = CSS.supports('selector(:has(*))');
    }

    if (!supportsHas && !document.body.classList.contains('bg-white')) {
      document.body.classList.add('bg-white');
    }

    return () => {
      document.body.classList.remove('bg-white');
    };
  }, [preferHas]);
}
