import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { propTypes } from 'formsy-react';

class InputWrapper extends PureComponent {

  wrapperRef = createRef();

  static propTypes = {
    ...propTypes,
    name: PropTypes.string.isRequired,
    inputId: PropTypes.string,
    classNames: PropTypes.string,
    autoCapitalize: PropTypes.string,
    label: PropTypes.any,
    labelAddon: PropTypes.any,
    labelSmall: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    showError: PropTypes.bool.isRequired,
    getErrorMessage: PropTypes.any,
    isFormDisabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    focus: PropTypes.bool,
    disabled: PropTypes.bool,
    newStyle: PropTypes.bool,
    invalid: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    children: PropTypes.node.isRequired,
    beforeAddon: PropTypes.node,
    afterAddon: PropTypes.node,
    hint: PropTypes.string,
    hintClassname: PropTypes.string,
    onClickOutside: PropTypes.func,
    validatePristine: PropTypes.bool,
    validateOnBlur: PropTypes.bool,
    hasBlurred: PropTypes.bool,
    prepend: PropTypes.node,
    append: PropTypes.node,
    id: PropTypes.string,
  };

  static defaultProps = {
    width: false,
    disabled: false,
    newStyle: false,
    invalid: false,
    labelAddon: false,
    labelSmall: false,
    beforeAddon: false,
    afterAddon: false,
    hintClassname: '',
    validatePristine: false,
    validateOnBlur: false,
    hasBlurred: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.props.onClickOutside && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.props.onClickOutside();
    }
  };

  renderLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <label className="form-control-label" htmlFor={ this.props.inputId ?? this.props.name }>
        { this.props.label }
        { this.props.labelAddon }
        { this.props.labelSmall && <small> { this.props.labelSmall }</small> }
        { this.props.required && '*' }
      </label>
    );
  }

  renderAddon(className, displayErrorMessage) {
    return (
      <fieldset className={ className }>
        { this.props.prepend }
        { this.renderLabel() }
        <div className="input-group mb-2 mr-sm-2 mb-sm-0" ref={ this.wrapperRef }>
          { this.props.beforeAddon && <div className="input-group-prepend"><div className="input-group-text">{ this.props.beforeAddon }</div></div> }
          { this.props.children }
          { this.props.afterAddon && <div className="input-group-append"><div className="input-group-text">{ this.props.afterAddon }</div></div> }
        </div>
        { displayErrorMessage && <span className="form-control-feedback">{ this.props.errorMessage }</span> }
        { (this.props.hint && !displayErrorMessage) &&
          <small className={ classnames('form-text text-muted', this.props.hintClassname) }>{ this.props.hint }</small>
        }
        { this.props.append }
      </fieldset>
    );
  }

  render() {
    const invalid = (this.props.showError || !this.props.isValid) &&
      (this.props.validatePristine || !this.props.isPristine) &&
      !(this.props.validateOnBlur && !this.props.hasBlurred);

    const className = classnames(
      'form-group',
      this.props.classNames,
      {
        'Select__fieldset': this.props.type === 'select',
        'col': this.props.width === false,
        [`col-${this.props.width}`]: this.props.width,
        'focus': this.props.focus,
        'has-danger': invalid,
        'disabled': this.props.isFormDisabled || this.props.disabled,
        'newStyle': this.props.newStyle,
      },
    );

    const errorMessage = this.props.errorMessage;
    const displayErrorMessage = (this.props.showError && errorMessage &&
      (this.props.validatePristine || !this.props.isPristine) &&
      !(this.props.validateOnBlur && !this.props.hasBlurred));

    if (this.props.beforeAddon || this.props.afterAddon) {
      return this.renderAddon(className, displayErrorMessage);
    }

    const props = {
      className,
      ref: ref => this.wrapperRef = ref,
    };

    if (this.props.id) {
      props.id = this.props.id;
    }

    return (
      <fieldset { ...props }>
        { this.props.prepend }
        { this.renderLabel() }
        { this.props.children }
        { displayErrorMessage && <div className="form-control-feedback">{ errorMessage }</div> }
        { (this.props.hint && !displayErrorMessage) &&
          <small className={ classnames('form-text text-muted', this.props.hintClassname) }>{ this.props.hint }</small>
        }
        { this.props.append }
      </fieldset>
    );
  }
}

export default InputWrapper;
