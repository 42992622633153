function buildQueryString(obj) {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)  }=${  encodeURIComponent(obj[key])}`)
    .join('&')
    .replace(/%20/g, '+');
}

/*
Request is a wrapper for fetch and allows for better error handling and
parsing of data.

`options` defaultOptions : { url, method, body, etc }
`root` :       Prepends a host to all non-qualified urls
`requestKey` : Tags the response of each request with this key for tracking
               the request, perhaps by which API is being used
*/

export default class Request {
  constructor(options = {}, root = CONFIG.API_ROOT, requestKey = undefined) {
    this.defaultOptions = options;
    this.root = root;
    this.requestKey = requestKey;
  }

  get(url, opts = {}) {
    opts.url = url;
    return this.send(opts);
  }

  post(url, data = {}, opts = {}) {
    opts.url = url;
    opts.method = 'post';
    opts.body = JSON.stringify(data);
    return this.send(opts);
  }

  put(url, data = {}, opts = {}) {
    opts.url = url;
    opts.method = 'put';
    opts.body = JSON.stringify(data);
    return this.send(opts);
  }

  delete(url, opts = {}) {
    opts.url = url;
    opts.method = 'delete';
    return this.send(opts);
  }

  upload(url, data = {}, opts = {}) {
    opts.url = url;
    opts.method = 'post';
    opts.body = data;
    return this.send(opts);
  }

  send(opts = {}) {
    var options = {
      method: 'get',
      ...this.defaultOptions,
      ...opts,
      headers: {
        'Content-Type': 'application/json',
        ...this.defaultOptions.headers,
        ...opts.headers,
      },
    };

    let url = options.url;
    if (!url) {
      throw new Error('Please include a `url` option for each request.');
    }
    delete options.url;

    if (url.search(/^http/i) !== 0) {
      url = this.root + url;
    }

    if (options.query) {
      url += `?${buildQueryString(options.query)}`;
    }

    const stack = (new Error()).stack;
    return fetch(url, options)
      .then(this.checkStatus(stack))
      .then(this.parseResponse);
  }

  checkStatus = stack => response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      return response.json().then(data => {
        const error = new Error(response.statusText);
        error.response = response;
        error.data = data;
        error.stack = stack;
        throw error;
      });
    }
  };

  parseResponse = response => {
    if (this.requestKey) {
      return response.json().then(resp => ({ ...resp, requestKey: this.requestKey }));
    }

    return response.json();
  };
}
