import { Button } from 'wiw/ui';
import FontIcon from './FontIcon';

function CloseButton(props) {
  return (
    <Button color="secondary" size="sm" iconOnly { ...props }>
      <FontIcon icon="close" />
    </Button>
  );
}

export default CloseButton;
