import { Fragment } from 'react';

type FontIconProps = {
  className?: string
  icon: string,
  srText?: string | null,
}

/**
 * Simple accessible font icon component
 *
 * @param {icon} string icon ( Icon names found here https://icons.wheniwork.com )
 * @param {className} string className
 * @param {srText} string srText ( optional screen reader text )
 *
 * @return {function(*): *} JSX.Element
 */
const FontIcon = ({ icon, className, srText }:FontIconProps) => (
  <Fragment>
    <i className={ `wiw-icon wiw-${icon} ${className || ''}` } title={ srText || null! } aria-hidden="true" />
    {srText && <span className="sr-only">{srText}</span>}
  </Fragment>

);

export default FontIcon;
