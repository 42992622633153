import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="container">
    <h1>Doh! 404!</h1>
    <p>These are <em>not</em> the droids you are looking for!</p>
    <p>
      <Link to='/'>Back to Dashboard</Link>
    </p>
  </div>
);

export default NotFound;
