import fetchAuthInfo from 'data/auth/actions/fetchAuthInfo';
import Person from 'data/person/model';
import { toEntityMap } from 'shared/util/toEntityMap';
import EntityState from 'store/EntityState';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    receivePerson: (state, action) => {
      return state.merge({
        receivedAt: Date.now(),
        items: toEntityMap(action.payload, Person),
        loaded: true,
        loading: false,
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAuthInfo.fulfilled, (state, action) => {
        return state.merge({
          receivedAt: Date.now(),
          items: toEntityMap(action.payload.person, Person),
          loaded: true,
          loading: false,
        });
      })
  },
});

export const { receivePerson } = personSlice.actions;
export default personSlice;
