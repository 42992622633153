import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import request from 'shared/request';
import { Dialog, Header, Body, Footer } from 'wiw/dialogs';
import Button from 'wiw/ui/Button';

export class DisableMFADialog extends Component {

  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  disableMFA = () => {
    request('login').post('/mfa/reset')
      .then(() => {
        window.location.assign(`${CONFIG.FRONTEND_URL}/profile`);
      });
  };

  render() {
    return (
      <Dialog width={500} className="approve-time-dialog">
        <Header onClose={this.props.closeDialog}>
          Change Two-step verification method
        </Header>
        <Body>
          <p>Are you sure you want to disable two step verification</p>
        </Body>
        <Footer>
          <div className="row justify-content-end  mr-1">
            <div className="col-auto mr-2">
              <Button block={false} onClick={this.props.closeDialog} color="secondary">Cancel</Button>
            </div>
            <div className="col-auto">
              <Button color="danger" onClick={this.disableMFA}>Disable</Button>
            </div>
          </div>
        </Footer>
      </Dialog>
    );
  }
}

export default connect(
  state => ({}),
  {},
)(DisableMFADialog);
