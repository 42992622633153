import { trackClick } from 'shared/util/tracking/mercury';

import { Link } from 'react-router-dom';

import 'login/styles/supportFooter.scss';

const SupportFooter = () => {
  const helpCenter = 'https://help.wheniwork.com';
  const marketingSite = 'https://wheniwork.com';
  return (
    <div className="footer">
      <div className="text-center support">
          Having trouble?
        <Link
          to={ { pathname: helpCenter } }
          target="_blank"
          rel="noopener noreferrer"
          onClick={ event => trackClick(event.target.innerText) }> We can help!</Link>
      </div>
      <div className="text-center links">
        <Link
          to={ { pathname: `${marketingSite}/terms` } }
          target="_blank"
          rel="noopener noreferrer"
          onClick={ event => trackClick(event.target.innerText) }>Terms of Service</Link>
        <span className="divider">|</span>
        <Link
          to={ { pathname: `${marketingSite}/privacy` } }
          target="_blank"
          rel="noopener noreferrer"
          onClick={ event => trackClick(event.target.innerText) }>Privacy Policy</Link>
      </div>
    </div>
  );
};

export default SupportFooter;
