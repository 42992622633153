import {
  CLEAR_ENTRY_INFO,
  CLEAR_LOGIN_DESTINATION,
  LOAD_REGID_INFO,
  SET_ENTRY_INFO,
  SET_LOGIN_DESTINATION,
} from 'store/actionTypes';
import { reduceClearEntryInfo, reduceSetEntryInfo } from 'store/reducers/entryInfo';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  complete: false,
  failureReason: null,
  loading: false,
  entry: {
    redirect: false,
    loginDestination: null,
  },
};

export const bootstrap = createSlice({
  name: 'bootstrap',
  initialState: initialState,
  reducers: {
    start: state => {
      state.loading = true;
      state.failureReason = null;
    },
    complete: state => {
      state.complete = true;
      state.loading = false;
    },
    failed: (state, action) => {
      state.complete = false;
      state.loading = false;
      state.failureReason = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SET_ENTRY_INFO, (state, action) => {
        reduceSetEntryInfo(state, action);
      })
      .addCase(CLEAR_ENTRY_INFO, (state, action) => {
        reduceClearEntryInfo(state, action);
      })
      .addCase(SET_LOGIN_DESTINATION, (state, action) => {
        state.loginDestination = action.path;
      })
      .addCase(CLEAR_LOGIN_DESTINATION, state => {
        state.loginDestination = undefined;
      })
      .addCase(LOAD_REGID_INFO.SUCCESS, (state, action) => {
        state.regidUser = action.user;
      })
      .addCase(LOAD_REGID_INFO.FAILURE, (state, action) => {
        state.regidUser = false;
      });
  },
});

export default bootstrap;
