import { useEffect } from 'react';

import { trackClick } from 'shared/util/tracking/mercury';
import { mixpanelTrack } from 'shared/vendor/mixpanel';
import { Body, Dialog, Footer, Header } from 'wiw/dialogs';
import Button from 'wiw/ui/Button';
import { ALREADY_REGISTERED_DIALOG } from './index';

import { useHistory } from 'react-router-dom';

type AlreadyRegisteredDialogProps = {
  closeDialog: () => void,
  message: string,
};

const AlreadyRegisteredDialog = ({ closeDialog, message }: AlreadyRegisteredDialogProps) => {
  const alreadyRegisteredField = message.indexOf('Email') >= 0 ? 'Email' : 'Mobile Number';

  useEffect(() => {
    trackClick('alreadyRegisteredDialog', { annotations: JSON.stringify({ dialog: ALREADY_REGISTERED_DIALOG, action: 'view' }) });
    mixpanelTrack(`View ${ alreadyRegisteredField.toLowerCase() } already in use dialog`);
  }, []);

  const history = useHistory();

  const goToLogin = (event: MouseEvent) => {
    const button = event.target as HTMLButtonElement;
    trackClick(button.innerText, { annotations: JSON.stringify({ dialog: ALREADY_REGISTERED_DIALOG }) });
    mixpanelTrack('Clicks email in use dialog', { 'Action taken on email in use dialog': 'Go to login' });
    history.push('/');
    closeDialog();
  };

  const handleClose = () => {
    trackClick('cancel', { annotations: JSON.stringify({ dialog: ALREADY_REGISTERED_DIALOG }) });
    mixpanelTrack('Clicks email in use dialog', { 'Action taken on email in use dialog': 'Cancel' });
    closeDialog();
  };

  return (
    <Dialog width={ 500 }>
      <Header onClose={ handleClose }>
        { alreadyRegisteredField } Already Exists
      </Header>
      <Body>
        <p>
          That { alreadyRegisteredField.toLowerCase() } is already used for a When I Work account.
          What would you like to do?
        </p>
      </Body>
      <Footer>
        <div className="row no-gutters justify-content-end mr-1">
          <div className="col-auto mr-2">
            <Button block={ false } onClick={ handleClose } color="secondary">Cancel</Button>
          </div>
          <div className="col-auto">
            <Button color="primary" onClick={ goToLogin }>Go to log in</Button>
          </div>
        </div>
      </Footer>
    </Dialog>
  );
};

export default AlreadyRegisteredDialog;
