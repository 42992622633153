/**
 * This middleware updates the Super Properties for mixPanel eventing
 * We target specific actions that update the properties we care about
 * Super Properties recorded are:
 * - CAT LD Flags
 * - FDT LD Flags
 */

import { Middleware } from 'redux';

import { mixpanelRegister } from 'shared/vendor/mixpanel';
import { RootState } from 'store';
import { ACCOUNTS_LIST } from 'store/actionTypes';

import { LDFlagChangeset, LDFlagSet } from 'launchdarkly-js-client-sdk';

const userActions = [
  'auth/setAuthUser',
  'users/receiveUsers',
];

const accountActions = [
  'auth/setAuthAccount',
  ACCOUNTS_LIST.SUCCESS,
];

const personActions = [
  'person/receivePerson',
  'auth/fetchAuthInfo/fulfilled',
];

const isSuperPropertyLDFlag = (key: string, substring: string) => {
  return key.includes(substring);
};

const isAccountAction = (type: string) => {
  return accountActions.includes(type);
};

const isUserAction = (type: string) => {
  return userActions.includes(type);
};

const isPersonAction = (type: string) => {
  return personActions.includes(type);
};

export const mixpanelUpdate: Middleware<{}, RootState> = store => next => action => {
  if (action.type === 'UPSERT_LD_FLAGS' && action.payload) {
    const ldFlags: LDFlagSet | LDFlagChangeset = {
      ...store.getState().LD, // LDFlagSet
      ...action.payload,      // LDFlagChangeset
    };

    const CATLDFlags = [];
    const FDTLDFlags = [];

    for (const [key, value] of Object.entries(ldFlags)) {
      if (isSuperPropertyLDFlag(key, 'cat-')) {
        CATLDFlags.push(`${ key }: ${ typeof value === 'object' ? value?.current : value }`);
      } else if (isSuperPropertyLDFlag(key, 'fdt-')) {
        FDTLDFlags.push(`${ key }: ${ typeof value === 'object' ? value?.current : value }`);
      }
    }

    mixpanelRegister({ 'CAT LD Flags': CATLDFlags, 'FDT LD Flags': FDTLDFlags });
  }

  if (isPersonAction(action.type)) {
    if (action.type === 'person/receivePerson') {
      mixpanelRegister({ 'Person created date': action.payload?.createdAt });
    }
    if (action.type === 'auth/fetchAuthInfo/fulfilled') {
      mixpanelRegister({ 'Person created date': action.payload?.person?.createdAt });
    }
  }

  if (isAccountAction(action.type)) {
    if (action.type === 'auth/setAuthAccount') {
      const accountId = action.payload;
      const account = store.getState().data.accounts?.items?.get(accountId);
      if (account) {
        mixpanelRegister({ 'Account created date': account.createdAt });
      }
    }
    if (action.type === ACCOUNTS_LIST.SUCCESS) {
      const accounts = action.payload;
      const authAccountId = store.getState().auth.accountId;
      if (authAccountId && accounts[authAccountId]) {
        mixpanelRegister({ 'Account created date': accounts[authAccountId].createdAt });
      }
    }
  }

  if (isUserAction(action.type)) {
    if (action.type === 'auth/setAuthUser') {
      const userId = action.payload;
      const user = store.getState().data.users?.items?.get(userId);
      if (user) {
        mixpanelRegister({ 'User created date': user.createdAt });
      }
    }
    if (action.type === 'users/receiveUsers') {
      const users = action.payload;
      const authUserId = store.getState().auth.userId;
      if (authUserId && users[authUserId]) {
        mixpanelRegister({ 'User created date': users[authUserId].createdAt });
      }
    }
  }

  return next(action);
};
