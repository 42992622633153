import Login from 'shared/api/login';

import { createAsyncThunk } from '@reduxjs/toolkit';

export default createAsyncThunk(
  'auth/fetchAuthInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Login.get('/people/me');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
