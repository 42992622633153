import { immerable } from 'immer';
import { Map, Record } from 'immutable';

const EntityBase = defaultValues => class EntityBase extends Record({
  loading: false,
  loaded: false,
  error: null,
  receivedAt: 0,
  items: new Map(),
  ...defaultValues,
}) {
  constructor(args) {
    super(args);
    this[immerable] = true;
  }
};


export default EntityBase;
