import { Component } from 'react';
import PropTypes from 'prop-types';

import { getBranches } from 'shared/dev/api/actions';
import BranchPicker from './BranchPicker';

import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

class ReactBranches extends Component {

  static propTypes = {
    float: PropTypes.oneOf(['left', 'right']).isRequired,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    float: 'left',
  };

  state = {
    currentBranch: Cookies.get('X-App-Branch', { domain: `.${CONFIG.ROOT_DOMAIN}` }) || 'master',
  };

  loadBranches(query) {
    // istanbul ignore next
    const projectID = 3;
    return getBranches(projectID, query);
  }

  changeBranch = branch => {
    Cookies.set('X-App-Branch', branch.value, { domain: `.${CONFIG.ROOT_DOMAIN}` });
    this.setState({ currentBranch: branch.value });
    window.location.reload();
  };

  render() {
    return (
      <BranchPicker
        { ...this.props }
        currentBranch={ this.state.currentBranch }
        loadBranches={ this.loadBranches }
        changeBranch={ this.changeBranch }
        branchLink={
          <a
            href={ encodeURI(`https://beta.wheniwork.net/cmd/choose?branch=${this.state.currentBranch}&redirect=/`) }>
            Link to branch
          </a>
        }
      />
    );
  }
}

export default ReactBranches;
