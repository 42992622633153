import { getAnonymousUserId, getToken, getUserId } from 'shared/auth';
import { Request } from 'wiw/utils';

import Cookies from 'js-cookie';

export const request = function (requestKey) {
  const host = () => {
    switch (requestKey) {
      case 'api':
        return CONFIG.API_ROOT_WORKZONES;
      case 'platform':
        return CONFIG.API_ROOT_PLATFORM;
      case 'login':
      default:
        requestKey = undefined;
        return CONFIG.API_ROOT;
    }
  };

  const headers = {};

  if (getToken()) {
    headers['W-Token'] = getToken();
    headers['Authorization'] = getToken();
  }

  if (getUserId()) {
    headers['W-UserId'] = getUserId();
  }

  if (requestKey === 'api' && getAnonymousUserId()) {
    headers['W-LDAnonymousId'] = getAnonymousUserId();
  }

  // Only add branch header for requests to monolith
  if (requestKey === 'api' && CONFIG.ENV !== 'production') {
    headers.Branch = Cookies.get('branch') || 'master';
  }

  return new Request({
    headers,
  }, host(), requestKey);
};

export default request;
