import {
  DIALOG_OPEN,
  DIALOG_UPDATE,
  DIALOG_CLOSE,
} from 'store/actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case DIALOG_OPEN:
      return [{
        dialog: action.dialog,
        options: action.options,
        payload: action.payload,
      }, ...state];
    case DIALOG_UPDATE:
      return state.map(item => {
        if (item.dialog === action.dialog) {
          return {
            ...item,
            payload: action.payload,
            options: action.options,
          };
        } else {
          return item;
        }
      });
    case DIALOG_CLOSE:
      return state.filter(dialog => dialog.dialog !== action.dialog);
    default:
      return state;
  }
}
