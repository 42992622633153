type DividerProps = {
  children?: React.ReactNode
}

const Divider = ({ children }:DividerProps) => (
  <strong className='form-divider'>
    <span>{ children }</span>
    <hr />
  </strong>
);

export default Divider;
