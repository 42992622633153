import { combineReducers } from 'redux';

import { SET_TEMP_ACCOUNT, SET_TEMP_USER } from 'store/actionTypes';

const initalState = {
  pendingAccount: {},
  pendingUser: {},
};

export default function (state = initalState, action) {
  switch (action.type) {
    case SET_TEMP_ACCOUNT:
      return {
        ...state,
        pendingAccount: action.payload,
      };

    case SET_TEMP_USER:
      return {
        ...state,
        pendingUser: action.payload,
      };


    default:
      return state;
  }
}
