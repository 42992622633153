import { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { withFormsy } from 'formsy-react';

import { FormGroup, FormFeedback } from 'reactstrap';


export class TextArea extends Component {

  static propTypes = {
    showError: PropTypes.bool,
    isPristine: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    rows: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    cols: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    setValue: PropTypes.func,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    isFormDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    width: PropTypes.number,
    isValid: PropTypes.bool,
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = {
    rows: 3,
    cols: 0, // React doesnt render col if 0
    width: 12,
  };

  render() {
    const invalid = (this.props.showError || !this.props.isValid) && !this.props.isPristine;

    const className = classnames(
      'form-group',
      `col-md-${this.props.width}`,
      {
        'has-danger': invalid,
      },
    );

    const inputClassname = classnames(
      'form-control',
      this.props.className,
      {
        'form-control-danger': invalid,
      },
    );

    return (
      <FormGroup color={invalid ? 'danger' : ''}>
        <textarea
          name={this.props.name}
          cols={this.props.cols}
          rows={this.props.rows}
          className={inputClassname}
          onChange={(e) => this.props.setValue(e.target.value)}
          onBlur={(e) => this.props.setValue(e.target.value)}
          value={this.props.value || this.props.label}
          disabled={this.props.isFormDisabled || this.props.disabled}
        >
        </textarea>
        {(!this.props.isPristine && this.props.showError) &&
        <FormFeedback>{this.props.errorMessage} </FormFeedback>}
      </FormGroup>
    );
  }
}

export default withFormsy(TextArea);
