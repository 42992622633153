import { Component } from 'react';
import PropTypes from 'prop-types';

import { InputWrapper } from 'shared/components/form/inputs';

import { withFormsy } from 'formsy-react';
import { isFunction } from 'lodash';
import { Input as BootstrapInput } from 'reactstrap';

export class Input extends Component {

  state = {
    canShowError: false,
  };

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'color',
      'date',
      'datetime',
      'datetime-local',
      'hidden',
      'month',
      'number',
      'password',
      'range',
      'search',
      'tel',
      'text',
      'time',
      'url',
      'week',
    ]),
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,

    // withFormsy props
    // withFormsy props
    isValid: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
    value: PropTypes.any,

    autocomplete: function (props, propName, componentName) {
      if (props[propName] !== undefined) {
        return new Error('Use `autoComplete`, not `autocomplete`.');
      }
    },
  };

  static defaultProps = {
    type: 'text',
    disabled: false,
    width: 12,
  };

  modifyValue = ({ target }) => {
    if (!target) {
      return;
    }

    let value = target.value;
    if (isFunction(this.props.mask) && this.props.value != value) {
      value = this.props.mask(value);
    }

    // If the field gets emptied out, turn off errors again
    // so they don't appear as the user types
    if (!value) {
      this.setState({ canShowError: false });
    }

    return this.props.setValue(value);
  };

  onBlur = () => {
    this.setState({ focus: false });
    // If the field has been blurred, show error messages
    this.setState({ canShowError: true });
  };
  onFocus = () => this.setState({ focus: true });

  renderInput(invalid) {
    return (
      <BootstrapInput
        id={ this.props.name }
        name={ this.props.name }
        type={ this.props.type }
        invalid={ invalid }
        value={ this.props.value || '' }
        onBlur={ this.onBlur }
        autoCapitalize="none"
        onChange={ this.modifyValue }
        onFocus={ this.onFocus }
        disabled={ this.props.disabled }
        autoComplete={ this.props.autoComplete }
        { ...this.props.inputProps }
      />
    );
  }

  render() {
    const invalid = (this.props.showError || !this.props.isValid) &&
      (this.props.validatePristine || !this.props.isPristine) &&
      !(this.props.validateOnBlur && !this.state.canShowError);

    if (this.props.type === 'hidden') {
      return this.renderInput(invalid);
    }

    return (
      <InputWrapper invalid={ invalid } hasBlurred={ this.state.canShowError } { ...this.props } focus={ this.state?.focus }>
        { this.renderInput(invalid) }
      </InputWrapper>
    );
  }
}

export default withFormsy(Input);
