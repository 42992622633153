import PropTypes from 'prop-types';

import { matchPath, Redirect as RouterRedirect } from 'react-router-dom';

export default function Redirect(props) {
  Redirect.propTypes = {
    from: PropTypes.string,
    to: PropTypes.string.isRequired,
    location: PropTypes.object,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    passSearch: PropTypes.bool,
  };

  if (!props.from) {
    return <RouterRedirect to={ props.passSearch ? `${props.to}${props.location.search}` : props.to } />;
  }

  const match = matchPath(props.location.pathname, {
    path: props.from,
    exact: props.exact,
    strict: props.strict,
  });

  if (match && match.isExact) {
    return <RouterRedirect to={ props.passSearch ? `${props.to}${props.location.search}` : props.to } />;
  }

  return null;
}
