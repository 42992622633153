import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export class PhoneUtil {
  formats = PhoneNumberFormat;

  constructor() {
    this.phoneUtil = PhoneNumberUtil.getInstance();
    this.defaultCountry = 'US';
    this.defaultFormat = PhoneNumberFormat.E164;
  }

  /**
   *
   * @param country
   */
  set country(country) {
    this.defaultCountry = country;
  }

  /**
   *
   * @param format
   */
  set phoneFormat(format) {
    this.defaultFormat = format;
  }

  /**
   *
   * @param number
   * @param country
   * @returns {*}
   */
  parse(number, country = this.defaultCountry) {
    return this.phoneUtil.parse(number, country);
  }

  /**
   *
   * @param number
   * @param country
   * @returns {boolean}
   */
  isValidNumber(number, country = this.defaultCountry) {
    try {
      const parsed = this.parse(number, country);
      return this.phoneUtil.isValidNumber(parsed);
    } catch (e) {
      return false;
    }

  }

  /**
   *
   * @param number
   * @param format
   * @returns {*}
   */
  format(number, format = this.defaultFormat) {
    try {
      const parsed = this.parse(number, this.defaultCountry);
      return this.phoneUtil.format(parsed, format);
    } catch (e) {
      throw new Error(e);
    }
  }

  formatNational(number) {
    return this.format(number, PhoneNumberFormat.NATIONAL);
  }

  formatServer(number) {
    return this.format(number, PhoneNumberFormat.E164);
  }
}

export default new PhoneUtil();
