import SupportFooter from 'login/components/SupportFooter';

import classnames from 'classnames';

type PageContainerProps = {
  className: string
  pageName?: string
  children?: React.ReactNode;
}

const PageContainer = ({ className, children, pageName }: PageContainerProps) => (
  <div className={ classnames('wiw-container', className) } data-view={ pageName }>
    { children }
    <SupportFooter />
  </div>
);

export default PageContainer;
