import { Component } from 'react';
import PropTypes from 'prop-types';

import { getBranches } from 'shared/dev/api/actions';
import BranchPicker from './BranchPicker';

import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

class LoginBranches extends Component {

  static propTypes = {
    float: PropTypes.oneOf(['left', 'right']).isRequired,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    float: 'left',
  };

  state = {
    currentBranch: Cookies.get('X-Login-Branch', { domain: `.${CONFIG.ROOT_DOMAIN}` }) || 'master',
  };

  loadBranches(query) {
    // istanbul ignore next
    const projectID = 25;
    return getBranches(projectID, query);
  }

  changeBranch = branch => {
    Cookies.set('X-Login-Branch', branch.value, { domain: `.${CONFIG.ROOT_DOMAIN}` });
    this.setState({ currentBranch: branch.value });
    window.location.reload();
  };

  render() {
    return (
      <BranchPicker
        { ...this.props }
        currentBranch={ this.state.currentBranch }
        loadBranches={ this.loadBranches }
        changeBranch={ this.changeBranch }
        branchLink={
          <Link to={ {
            pathname: '/cmd/choose',
            query: {
              branch: this.state.currentBranch,
              redirect: window.location.pathname,
            },
          }
          }>Link to branch</Link>
        }
      />
    );
  }
}

export default LoginBranches;
