import { useDispatch } from 'react-redux';

import attemptLogout from 'data/auth/actions/logout';
import { trackClick } from 'shared/util/tracking/mercury';
import { PanelTitle } from 'wiw/ui';
import FontIcon from 'wiw/ui/FontIcon';

import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import 'shared/assets/styles/smallHeader.scss';
import LogoSmall from 'shared/assets/wiw-icon.svg';
import LogoBig from 'shared/assets/wiw-logo.svg';

const SmallHeader = ({ logout, back }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const clickedBack = () => {
    if (window.location.pathname === '/mfa/confirm') {
      dispatch(attemptLogout());
      return history.push('/');
    }
    history.goBack();
  };

  return (
    <PanelTitle className="small-header text-center align-items-center">
      {back &&
          <Button
            className="pull-left back"
            size="md"
            color="link"
            onClick={ event => { trackClick(event.target.innerText); clickedBack(); } }>
            <FontIcon icon="arrow-left" />
            Back
          </Button>
      }
      <picture>
        <source srcSet={ LogoBig } media="(min-width: 426px)" />
        <img src={ LogoSmall } alt="When I Work" />
      </picture>
      {logout &&
          <Button
            className="pull-right logout"
            size="md"
            color="link"
            onClick={ event => { trackClick(event.target.innerText); dispatch(attemptLogout()); } }>
            <FontIcon icon="power-on-off" />
            Logout
          </Button>
      }
    </PanelTitle>
  );
};


export default SmallHeader;
