import Cookies from 'js-cookie';

const axiosMethods = [
  'get',
  'delete',
  'head',
  'options',
  'post',
  'put',
  'patch',
  'purge',
  'link',
  'unlink',
];

export default function toAxiosConfig(options) {
  if (options.method && !axiosMethods.includes(options.method.toLowerCase())) {
    throw new Error(`Unrecognized request method: ${ options.method }`);
  }

  const config = {};

  if (options.baseURL) {
    config.baseURL = options.baseURL;
  }

  // because people are dumb
  if (options.baseUrl) {
    config.baseURL = options.baseUrl;
  }

  if (options.method) {
    config.method = options.method;
  }

  if (options.url) {
    config.url = options.url;
  }

  if (options.headers) {
    config.headers = options.headers;
  }

  if (options.query) {
    config.params = options.query;
  }

  if (options.body) {
    config.data = options.body;
  }

  if (options.responseType) {
    config.responseType = options.responseType;
  }

  if (options.token) {
    config.headers = {
      Authorization: `Bearer ${ options.token }`,
      'W-Token': options.token,
    };
  }

  if (options.anonymousHeader && Cookies.get('ld_anon_context')) {
    config.headers = {
      'W-LDAnonymousId': Cookies.get('ld_anon_context'),
      ...config.headers,
    };
  }

  return config;
}
