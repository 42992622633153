import { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeDialog } from '../actions';
import { DialogContext } from 'wiw/dialogs/context';
import getDialogs from '../dialogs';
import { errorNotice } from 'wiw/notices';
import { Dialog, Header, Footer, Body } from 'wiw/dialogs';
import { Map } from 'immutable';

import classnames from 'classnames';

export class DialogContainer extends Component {
  static propTypes = {
    dialogs: PropTypes.array.isRequired,
    closeDialog: PropTypes.func,
    errorNotice: PropTypes.func,
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    errorMessage: 'Well, this is embarrassing. Our program just needed a breather. Can you try again?',
  };

  state = {
    err: null,
    errInfo: null,
    onDismiss: Map(),
  };

  componentDidCatch(err, errInfo) {
    // Preserve error for...posterity?
    this.setState({ err, errInfo });

    this.props.errorNotice(this.props.errorMessage);
  }

  setOnDismiss = name => onDismiss => {
    this.setState(prev => ({ onDismiss: prev.onDismiss.set(name, onDismiss) }));
  };

  closeDialog = name => ({ keyEvent, force } = {}) => {
    if (
      force !== true &&
      this.state.onDismiss.get(name) &&
      this.state.onDismiss.get(name)(keyEvent) === false
    ) {
      // Do not close the dialog
      return;
    }

    this.setState(prev => ({ err: null, errInfo: null, onDismiss: prev.onDismiss.delete(name) }));
    this.props.closeDialog(name);
  };

  onKeyPressed = event => {
    const { dialogs } = this.props;
    if (!dialogs.length) {
      return;
    }
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.stopPropagation();
      this.closeDialog(dialogs[0].dialog)({ keyEvent: event });
    }
  };

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyPressed);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyPressed);
  }

  renderLoadingDialog(closeDialogFn) {
    return (
      <Dialog width={ 400 }>
        <Header onClose={ closeDialogFn } />
        <Body>
          <div style={ { height: 100, position: 'relative' } }>
            Loading...
          </div>
        </Body>
        <Footer />
      </Dialog>
    );
  }

  renderDialog(dialog) {
    const name = dialog.dialog;

    const transformedData = getDialogs(this.props.state);

    // Find the Dialog Component
    // If missing, clear from redux and warn.
    const DialogNode = transformedData[name];
    const closeDialogFn = this.closeDialog(name);
    if (!DialogNode) {
      console.warn(`Cannot find dialog with key ${name}`);
      closeDialogFn();
      return null;
    }

    // Wrap any dialog style in the Context Provider
    return (
      <DialogContext.Provider value={ {
        closeDialog: closeDialogFn,
        setOnDismiss: this.setOnDismiss(name),
        isInDialog: true,
        err: this.state.err,
        errInfo: this.state.errInfo,
      } }>
        <Suspense fallback={ this.renderLoadingDialog(closeDialogFn) }>
          <DialogNode { ...dialog.payload } closeDialog={ closeDialogFn } />
        </Suspense>
      </DialogContext.Provider>
    );
  }

  render() {
    const dialogList = [];

    if (this.props.dialogs.length) {
      let backgroundDialog = false;
      for (const i in this.props.dialogs) {
        const dialog = this.props.dialogs[i];
        const prevDialog = this.props.dialogs[i - 1];
        const rendered = this.renderDialog(dialog);
        if (rendered) {
          const style = dialog.options.style || 'modal';
          dialogList.unshift(
            <div
              key={ dialog.dialog }
              className={ classnames({
                'dialog-kit-scroll dimmed skip-click-collapse': style === 'modal',
                'hidden-xl-down':
                  prevDialog && prevDialog.options.overlay && prevDialog.options.overlayHidden,
                'background-dialog': backgroundDialog,
              }) }
            >
              { rendered }
            </div>,
          );
          backgroundDialog = true;
        }
        if (!dialog.options.overlay) {
          break;
        }
      }
    }

    return (
      <div className="dialogs--holder">
        { dialogList }
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      // This is gross, but required for handling dialog transformers
      state,
      dialogs: state.dialogs,
    };
  },
  dispatch => bindActionCreators({
    closeDialog,
    errorNotice,
  }, dispatch),
)(DialogContainer);
